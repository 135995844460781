import { Html } from "@react-three/drei"
import "../gameStyle.css"

export default function KeysExplenation() 
{
    return <div className="keysExplenation">
        <svg width="100%" height="100%">

        {/* W */}
            <rect
                x="40" y="0"
                width="50" 
                height="50" 
                fill="none" 
                stroke="white" 
                strokeWidth="1.5" 
                rx="5" ry="5"
            />
            <text
                className="svg__text"
                x="65" y="25" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fill="white">
                    W
            </text>

        {/* A */}
            <rect 
                x="0" y="60"
                width="50" 
                height="50" 
                fill="none" 
                stroke="white" 
                strokeWidth="1.5" 
                rx="5" ry="5"
            />
            <text
                className="svg__text"
                x="25" y="85" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fill="white">
                    A
            </text>

        {/* S */}
            <rect 
                x="60" y="60"
                width="50" 
                height="50" 
                fill="none" 
                stroke="white" 
                strokeWidth="1.5" 
                rx="5" ry="5"
            />
            <text
                className="svg__text"
                x="85" y="85" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fill="white">
                    S
            </text>

        {/* D */}
            <rect 
                x="120" y="60"
                width="50" 
                height="50" 
                fill="none" 
                stroke="white" 
                strokeWidth="1.5" 
                rx="5" ry="5"
            />
            <text
                className="svg__text"
                x="145" y="85" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fill="white">
                    D
            </text>

        {/* SPACE */}
            <rect 
                x="0" y="145"
                width="170" 
                height="50" 
                fill="none" 
                stroke="white" 
                strokeWidth="1.5" 
                rx="5" ry="5"
            />
            <text
                className="svg__text"
                x="50" y="170" 
                dominantBaseline="middle" 
                textAnchor="middle" 
                fill="white">
                    SPACE
            </text>

        </svg>
    </div>

}