import Navigation from './Navigation.jsx';
import Images from './Images.jsx';
import App from '../gameSrc/App.jsx';
import { useState } from 'react';
import Welcome from './Welcome.jsx';
import '../style/images.css'

export default function TheGameOfStuttering({ data }) {
    const [isChromeOrFirefox, setIsChromeOrFirefox] = useState(false);
    const [windowSmall, setWindowSmall] = useState(window.innerWidth <= 501)

    // Check if the user is using Chrome or Firefox
    useState(() => 
    {
        const isChromeOrFirefox = /Chrome|Firefox/.test(navigator.userAgent);
        setIsChromeOrFirefox(isChromeOrFirefox);
    }, 
    []);

    return (
        <>
            <Welcome />
            <Navigation data={data} background="transparent" />

            {
            (isChromeOrFirefox && !windowSmall ) ? <App /> : 
                <div
                    className="images__ansichten"
                >
                    <img
                        src={data.sources[0].srcset[8]}
                        alt=""
                        className="ansichten__image"
                    />
                    <div className="ansichten__warning">
                            Use another Computer browser<br></br>to play the Game of Stuttering
                    </div>
                </div>
            }

            <Images data={data} />
        </>
    );
}
