import React, { useState, useEffect } from 'react';
import '../style/images.css';

export default function Lve({ data }) {
    const [typedSequence, setTypedSequence] = useState('');
    const sequence = 'mascha';
    const timeLimit = 5000; // Time limit in milliseconds
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        let typingTimeout;

        const handleKeyDown = (event) => {
            clearTimeout(typingTimeout);
            setTypedSequence((prevSequence) => prevSequence + event.key);
            typingTimeout = setTimeout(() => {
                setTypedSequence('');
            }, timeLimit);
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            clearTimeout(typingTimeout);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (typedSequence === sequence) {
            setShowVideo(true);
            // Reset the typed sequence after showing the video
            setTimeout(() => {
                setTypedSequence('');
            }, timeLimit);
        }
    }, [typedSequence]);


    return (
        <>
            {data.main.title === 'Animate FG and JG' && showVideo && (
                <div className="love">
                    <video
                        key={1}
                        src="/images/jungeGrafik/love/video.mp4"
                        className="love__video"
                        autoPlay
                        loop={false}
                    />
                </div>
            )}
        </>
    );
}
