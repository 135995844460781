import { Canvas } from '@react-three/fiber';
import { useState, useRef, useEffect } from 'react';
import { OrthographicCamera } from '@react-three/drei';
import { gsap } from 'gsap';
import * as THREE from 'three'
import { ImageMap } from '@qiuz/react-image-map';
import useGame from '../gameSrc/stores/useGame';
import useGlobal from '../stores/useGlobal';
import 'lazysizes';

import '../style/isometries.css';
import '../style/sizes.css';

import { data } from '../data/data';

export default function Isometries({ main }) 
{
    const isometriesRef = useRef(null);

    const [isResizing, setIsResizing] = useState(false);
    const windowSmall = useGlobal(state => state.windowSmall)
    const setWindowSmall = useGlobal(state => state.setWindowSmall)
    const isometriesOpen = useGlobal(state => state.isometriesOpen)

    console.log(location.pathname)
    /* 
    *** Window resize
    */
    useEffect(() => 
    {
        const debounce = (func, delay) => 
        {
            let timeoutId;
            return function (...args) {
                if (timeoutId) clearTimeout(timeoutId);
                timeoutId = setTimeout(() => 
                {
                    func.apply(this, args);
                }, delay);
            };
        };

        const handleResize = debounce(() => 
        {
            setIsResizing(true);
        }, 250);

        window.addEventListener('resize', handleResize);

        return () => 
        {
            window.removeEventListener('resize', handleResize);
        };
    }, 
    []);

    useEffect(() => 
    {
        if (isResizing) 
        {
            setIsResizing(false);
        }
    }, 
    [isResizing]);


    /* Set Window Small */

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 501) {
            setWindowSmall(true)
        } else {
            setWindowSmall(false)
        }
    })


    /* 
    *** isometries animations
    */
    useEffect(() => 
    {
        const isometriesContainer = isometriesRef.current;
        const isometries = isometriesRef.current.querySelectorAll('.container__isometrie');
        const isometriesImages = isometriesRef.current.querySelectorAll('.isometrie__image');

        if (isometriesOpen === true) 
        {
            gsap.to(isometries, {
                marginTop: windowSmall ? -120 : window.innerHeight / 18 - 20 - (windowSmall ? 60 : 125),
                stagger: 0.2,
                duration: 1,
                ease: 'bounce'
            });

            gsap.to(isometriesImages, {
                filter: `drop-shadow(0 ${ windowSmall ? 40 : 16 }px 20px rgba(0, 0, 0, ${ windowSmall ? 0.6 : 0.3 }))`,
                stagger: 0.2,
                duration: 1,
                ease: 'ease',
            });

            window.innerWidth <= 501 ? isometriesContainer.style.display = "flex" : ""
        } 
        else 
        {
            gsap.to(isometries, {
                marginTop: windowSmall ? -window.innerWidth : -145,
                stagger: 0.05,
                duration: 0.5,
                ease: 'ease'
            });

            gsap.to(isometriesImages, {
                filter: "drop-shadow(0 16px 20px rgba(0, 0, 0, 0))",
                stagger: 0.05,
                duration: 0.5,
                ease: 'ease',
            });

            window.innerWidth <= 501 ? isometriesContainer.style.display = "none" : ""
        }
    }, [isometriesOpen, windowSmall, isResizing]);
    
    useEffect(() => 
    {
        if (windowSmall === true) {
            window.scrollTo(0, 0);
        }
    }, [isometriesOpen])


    const firstProperty = main.page.slice(1);
    const allKeys = Object.keys(data);
    const keyOrder = allKeys.filter(key => key !== firstProperty);
    keyOrder.unshift(firstProperty);

    // Remove the last element from keyOrder
    keyOrder.pop();
    
    return (
        <div className="isometries">
            <div
                className="isometries__container"
                ref={isometriesRef}
            >

                {// Map through the key order and render components accordingly
                    keyOrder.map((key, index) => (
                        <Isometrie
                            key={key} // Make sure to add a unique key for each component
                            index={index}
                            value={data[key]} // Access value using the current key
                            isFirst={index === 0}
                        />
                    ))}

            </div>
        </div>
    );
}


function Isometrie({ index, value, isFirst }) 
{
    const setDynamicTitle = useGame(state => state.setDynamicTitle)

    // Animation function for mouse enter
    const handleMouseEnter = (event) => 
    {
        gsap.to(`.container__isometrie--${value.main.page.slice(1)}`, 
        {
            translateY: 160,
            duration: 0.4,
            ease: 'ease'
        });
        
        setDynamicTitle(event.target.attributes[3].nodeValue.slice(11))
    };

    // Animation function for mouse leave
    const handleMouseLeave = () => 
    {
        gsap.to(`.container__isometrie--${value.main.page.slice(1)}`, 
        {
            translateY: 130,
            duration: 1,
            ease: 'ease'
        });

        setDynamicTitle("")
    };

    const handleAreaClick = () => 
    {
        window.location.href = value.main.page;
    };

    /* Wiggle */
    useEffect(() => 
    {
        if (isFirst) 
        {
            const tl = gsap.timeline({ repeat: -1, yoyo: true });
            tl.to(`.container__isometrie--${value.main.page.slice(1)}`, 
            {
                rotation: 3,
                duration: 0.5,
                ease: 'power1.inOut'
            })

            .to(`.container__isometrie--${value.main.page.slice(1)}`, 
            {
                rotation: -3,
                duration: 0.5,
                ease: 'power1.inOut'
            });
        }
    }, 
    [isFirst, value.main.page]);


    return <>
        <div
            className={`
                container__isometrie 
                container__isometrie--${value.main.page.slice(1) }` 
            }
            style={{ zIndex: -index }}
            key={1}
        >
            <img
                src={`/images/${ value.main.page.slice(1) }/isometrie/isometrie${ value.isometrie.type }`}
                alt=""
                className="isometrie__image"
                useMap={`#imageMap__${ value.main.page.slice(1) }`}
                
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleAreaClick} // Add onClick handler
            />
        </div>
    </>
}