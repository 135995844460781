export const layoutBasics =
{
    main:
    {
        title: "Layout Basics",
        category: "Editorial",
        page: "/layoutBasics"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙160 &times; 210 <br> ∙180 &times; 230 <br> ∙220 &times; 290" },
            { title: "year", content: "∙2020 <br> ∙2021" },
            { title: "weight", content: "∙351 g <br> ∙202 g <br> ∙248 g" },
            { title: "printing", content: "∙digital" },
            { title: "binding", content: "∙Adhesive binding <br> ∙Thread stitching" },
        ],

    text:
    {
        adjectives:
            [
                { de: "fundamental", en: "fundamental" },
                { de: "Bergründungen", en: "Justifications" },
                { de: "humorvoll", en: "humorous" },
                // { de: "Dramaturgie", en: "Dramturgy" },
                { de: "typografisch", en: "typographic" },
            ],

        content:
        {
            de: `
            Diese drei Publikationen sind im ersten Ausbildungsjahr der Fachklasse Grafik entstanden und bildeten das Fundament für weitere, grössere Projekte. Die erste ist ein Lampenkatalog mit dem Fokus auf wenige, aber qualitativ hochwertige Seiten. Die Zweite ist ein Gemeinschaftsprojekt namens Typoxikon und legt Fokus darauf, einen typografischen Stiel zu entwickeln. Die dritte hat die Absicht, die Qualität dieser Seiten auf einen grösseren Umfang zu übertragen. Diese langsame Erweiterung des Umfanges und des Anspruches hilft mir, auch bei grösseren Projekten mein Augenmerk auf Ablauf, Dramaturgie sowie Mikrotypografie zu setzten. ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/layoutBasics/0_ansichten/400.jpg",
                        "/images/layoutBasics/0_ansichten/600.jpg",
                        "/images/layoutBasics/0_ansichten/800.jpg",
                        "/images/layoutBasics/0_ansichten/1000.jpg",
                        "/images/layoutBasics/0_ansichten/1500.jpg",
                        "/images/layoutBasics/0_ansichten/2000.jpg",
                        "/images/layoutBasics/0_ansichten/3000.jpg",
                        "/images/layoutBasics/0_ansichten/4000.jpg",
                        "/images/layoutBasics/0_ansichten/5000.jpg"
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/layoutBasics/1_swiper/1_image/400.jpg",
                            "/images/layoutBasics/1_swiper/1_image/600.jpg",
                            "/images/layoutBasics/1_swiper/1_image/800.jpg",
                            "/images/layoutBasics/1_swiper/1_image/1000.jpg",
                            "/images/layoutBasics/1_swiper/1_image/1500.jpg",
                            "/images/layoutBasics/1_swiper/1_image/2000.jpg",
                            "/images/layoutBasics/1_swiper/1_image/3000.jpg",
                            "/images/layoutBasics/1_swiper/1_image/4000.jpg",
                            "/images/layoutBasics/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/1_swiper/2_image/400.jpg",
                            "/images/layoutBasics/1_swiper/2_image/600.jpg",
                            "/images/layoutBasics/1_swiper/2_image/800.jpg",
                            "/images/layoutBasics/1_swiper/2_image/1000.jpg",
                            "/images/layoutBasics/1_swiper/2_image/1500.jpg",
                            "/images/layoutBasics/1_swiper/2_image/2000.jpg",
                            "/images/layoutBasics/1_swiper/2_image/3000.jpg",
                            "/images/layoutBasics/1_swiper/2_image/4000.jpg",
                            "/images/layoutBasics/1_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/1_swiper/3_image/400.jpg",
                            "/images/layoutBasics/1_swiper/3_image/600.jpg",
                            "/images/layoutBasics/1_swiper/3_image/800.jpg",
                            "/images/layoutBasics/1_swiper/3_image/1000.jpg",
                            "/images/layoutBasics/1_swiper/3_image/1500.jpg",
                            "/images/layoutBasics/1_swiper/3_image/2000.jpg",
                            "/images/layoutBasics/1_swiper/3_image/3000.jpg",
                            "/images/layoutBasics/1_swiper/3_image/4000.jpg",
                            "/images/layoutBasics/1_swiper/3_image/5000.jpg"
                        ],
                    ]
            },

            // swiper 2
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/layoutBasics/2_swiper/1_image/400.jpg",
                            "/images/layoutBasics/2_swiper/1_image/600.jpg",
                            "/images/layoutBasics/2_swiper/1_image/800.jpg",
                            "/images/layoutBasics/2_swiper/1_image/1000.jpg",
                            "/images/layoutBasics/2_swiper/1_image/1500.jpg",
                            "/images/layoutBasics/2_swiper/1_image/2000.jpg",
                            "/images/layoutBasics/2_swiper/1_image/3000.jpg",
                            "/images/layoutBasics/2_swiper/1_image/4000.jpg",
                            "/images/layoutBasics/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/2_swiper/2_image/400.jpg",
                            "/images/layoutBasics/2_swiper/2_image/600.jpg",
                            "/images/layoutBasics/2_swiper/2_image/800.jpg",
                            "/images/layoutBasics/2_swiper/2_image/1000.jpg",
                            "/images/layoutBasics/2_swiper/2_image/1500.jpg",
                            "/images/layoutBasics/2_swiper/2_image/2000.jpg",
                            "/images/layoutBasics/2_swiper/2_image/3000.jpg",
                            "/images/layoutBasics/2_swiper/2_image/4000.jpg",
                            "/images/layoutBasics/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/2_swiper/3_image/400.jpg",
                            "/images/layoutBasics/2_swiper/3_image/600.jpg",
                            "/images/layoutBasics/2_swiper/3_image/800.jpg",
                            "/images/layoutBasics/2_swiper/3_image/1000.jpg",
                            "/images/layoutBasics/2_swiper/3_image/1500.jpg",
                            "/images/layoutBasics/2_swiper/3_image/2000.jpg",
                            "/images/layoutBasics/2_swiper/3_image/3000.jpg",
                            "/images/layoutBasics/2_swiper/3_image/4000.jpg",
                            "/images/layoutBasics/2_swiper/3_image/5000.jpg"
                        ],
                    ]
            },

            // swiper 3
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/layoutBasics/3_swiper/1_image/400.jpg",
                            "/images/layoutBasics/3_swiper/1_image/600.jpg",
                            "/images/layoutBasics/3_swiper/1_image/800.jpg",
                            "/images/layoutBasics/3_swiper/1_image/1000.jpg",
                            "/images/layoutBasics/3_swiper/1_image/1500.jpg",
                            "/images/layoutBasics/3_swiper/1_image/2000.jpg",
                            "/images/layoutBasics/3_swiper/1_image/3000.jpg",
                            "/images/layoutBasics/3_swiper/1_image/4000.jpg",
                            "/images/layoutBasics/3_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/3_swiper/2_image/400.jpg",
                            "/images/layoutBasics/3_swiper/2_image/600.jpg",
                            "/images/layoutBasics/3_swiper/2_image/800.jpg",
                            "/images/layoutBasics/3_swiper/2_image/1000.jpg",
                            "/images/layoutBasics/3_swiper/2_image/1500.jpg",
                            "/images/layoutBasics/3_swiper/2_image/2000.jpg",
                            "/images/layoutBasics/3_swiper/2_image/3000.jpg",
                            "/images/layoutBasics/3_swiper/2_image/4000.jpg",
                            "/images/layoutBasics/3_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/layoutBasics/3_swiper/3_image/400.jpg",
                            "/images/layoutBasics/3_swiper/3_image/600.jpg",
                            "/images/layoutBasics/3_swiper/3_image/800.jpg",
                            "/images/layoutBasics/3_swiper/3_image/1000.jpg",
                            "/images/layoutBasics/3_swiper/3_image/1500.jpg",
                            "/images/layoutBasics/3_swiper/3_image/2000.jpg",
                            "/images/layoutBasics/3_swiper/3_image/3000.jpg",
                            "/images/layoutBasics/3_swiper/3_image/4000.jpg",
                            "/images/layoutBasics/3_swiper/3_image/5000.jpg"
                        ],
                    ]
            },


            // single 4
            {
                type: "single3D",
                srcset:
                    [
                        "/images/layoutBasics/4_single/400.jpg",
                        "/images/layoutBasics/4_single/600.jpg",
                        "/images/layoutBasics/4_single/800.jpg",
                        "/images/layoutBasics/4_single/1000.jpg",
                        "/images/layoutBasics/4_single/1500.jpg",
                        "/images/layoutBasics/4_single/2000.jpg",
                        "/images/layoutBasics/4_single/3000.jpg",
                        "/images/layoutBasics/4_single/4000.jpg",
                        "/images/layoutBasics/4_single/5000.jpg"
                    ],
                background: "white"
            },
        ],
}