import { SoftShadows, useKeyboardControls } from '@react-three/drei'
import { Physics } from '@react-three/rapier'
import { Perf } from 'r3f-perf'

import { KeyboardContext } from './Helpers/KeyboardContext.jsx'
import useGame from './stores/useGame.jsx'

import Lights from './Lights.jsx'
import Level from './Level.jsx'
import Player from './Player.jsx'

export default function Game()
{
    // Keyboard Variables
    const [subscribeKeys, getKeys] = useKeyboardControls()

    return <>

        {/* <Perf position="top-left" /> */}

        <KeyboardContext.Provider
            value={{
                subscribeKeys, getKeys
            }}
        >

        {/* Look */}
            <SoftShadows 
                size={ 25 }
                samples={ 30 }
                focus={ 0 }
            />
            <fog 
                attach="fog" 
                color="black" 
                near={ 4.6 } 
                far={ 10 }
            />

            <Physics gravity={ [ 0, -30, 0 ] }>
                <Lights />
                <Level />
                <Player />
            </Physics>

        </KeyboardContext.Provider>
    </>
}