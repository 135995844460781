import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware"

export default create(subscribeWithSelector((set) => {
    return {

        windowSmall: window.innerWidth <= 500 ? true : false,
        isometriesOpen: 
            (window.innerWidth <= 500 && location.pathname !== "/theGameOfStuttering") ? false 
            : window.innerWidth >= 500 ? true 
            : false,

        setWindowSmall: (value) => 
        {
            set(() => 
                {
                return { windowSmall: value }
                })
        },

        setIsometriesOpen: (value) => 
        {
            set(() => 
                {
                return { isometriesOpen: value }
                })
        },

    }
}))