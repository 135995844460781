import * as THREE from 'three'
import useGame from '../stores/useGame'
import { Html } from '@react-three/drei'
import { useState, useEffect } from 'react'

const circleGeometry = new THREE.CircleGeometry(0.5, 32)
const material = new THREE.MeshStandardMaterial({ color: 'black' })

export default function Breath({ 
    position, 
    onRemove,
    textures,
    geometries,
    materials
}) 
{
    const playerPosition = useGame(state => state.playerPosition)
    const setItemBreathCount = useGame(state => state.setItemBreathCount)
    const itemBreathCount = useGame(state => state.itemBreathCount)
    const phase = useGame(state => state.phase)

    const [y, setY] = useState(() => (Math.random() + 0.5) * 2)
    const tolerance = 0.7; // Set your desired tolerance value

    useEffect(() => {
        const distance = Math.sqrt(
            Math.pow(playerPosition.x - position[0], 2) +
            Math.pow(playerPosition.y - (position[1] + y), 2)
        );

        if (distance <= tolerance) {
            onRemove(); // Notify the parent to remove the component
            setItemBreathCount(itemBreathCount + 1)
        }
    }, [playerPosition, position, y, tolerance, onRemove]);

    return <group position={[position[0], position[1] + 0.5, position[2]]}>
        
        <mesh
            position={[0, y, 0]}
            geometry={circleGeometry}
            // material={material}
            scale={ 0.5 }
        >
            <meshStandardMaterial transparent opacity={0} attach="material" />

            <Html className='items__breath' center>
                5
            </Html>

        </mesh>


            <Blending
                textures={textures}
                planeGeometry={geometries.planeGeometry}
                randomNum={y}
            />
    </group>
}


function Blending({
    textures,
    planeGeometry,
    strength,
    position,
    randomNum
}) {
    
    return <>
        <mesh
            geometry={planeGeometry}
            scale={[3, 3, 0]}
            position={ [ 0, 2, randomNum * 6 ] }
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.breath_2}
            />
        </mesh>
    </>
}