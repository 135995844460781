export const ineffizienz =
{
    main:
    {
        title: "Ineffizienz",
        category: "Editorial&#8198;/&#8198;Poster",
        page: "/ineffizienz"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙420 &times; 297 <br> ∙895 &times; 1280 " },
            { title: "year", content: "∙2022" },
            { title: "weight", content: "∙18 g <br> ∙24 g" },
            { title: "printing", content: "∙digital" },
            { title: "binding", content: "∙no binding" },
        ],

    text:
    {
        adjectives:
            [
                { de: "analog", en: "analog" },
                { de: "experimentell", en: "experimental" },
                { de: "ehrlich", en: "plain" },
                { de: "atmosphärisch", en: "atmospheric" },
                { de: "radikal", en: "radical" },
            ],

        content:
        {
            de: `Durch pflanzliche Lebensmittel kann man 10-mal mehr Menschen ernähren als durch tierische! Das ist das Statement, welches ich mit meinem Plakat machen möchte. Ich möchte für einen bewussten Konsum von tierischen Lebensmitteln anregen. Dies soll nicht auf eine belehrende Art geschehen, sondern auf eine «Haha da ist eine fette Kuh» Art. Vielleicht wird dadurch der Diskurs über die Ineffizienz tierischen Lebensmitteln wieder mehr angeregt. Das übergreifende Thema dieses Plakatprojektes war ‹Energie›. Einige der Plakate wurden in einer kleinen Auflage gedruckt. Als Nebenprodukt ist eine Zeitung entstanden ‹Das Ring als Nutztier›, welche sich eher auf das Leben der ausgebeuteten Tiere fokussiert und beinhaltet vor allem Fotogramme. Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können. Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu. Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/ineffizienz/0_ansichten/400.jpg",
                        "/images/ineffizienz/0_ansichten/600.jpg",
                        "/images/ineffizienz/0_ansichten/800.jpg",
                        "/images/ineffizienz/0_ansichten/1000.jpg",
                        "/images/ineffizienz/0_ansichten/1500.jpg",
                        "/images/ineffizienz/0_ansichten/2000.jpg",
                        "/images/ineffizienz/0_ansichten/3000.jpg",
                        "/images/ineffizienz/0_ansichten/4000.jpg",
                        "/images/ineffizienz/0_ansichten/5000.jpg"
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/ineffizienz/1_swiper/1_image/400.jpg",
                            "/images/ineffizienz/1_swiper/1_image/600.jpg",
                            "/images/ineffizienz/1_swiper/1_image/800.jpg",
                            "/images/ineffizienz/1_swiper/1_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/1_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/1_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/1_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/1_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/2_image/400.jpg",
                            "/images/ineffizienz/1_swiper/2_image/600.jpg",
                            "/images/ineffizienz/1_swiper/2_image/800.jpg",
                            "/images/ineffizienz/1_swiper/2_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/2_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/2_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/2_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/2_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/3_image/400.jpg",
                            "/images/ineffizienz/1_swiper/3_image/600.jpg",
                            "/images/ineffizienz/1_swiper/3_image/800.jpg",
                            "/images/ineffizienz/1_swiper/3_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/3_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/3_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/3_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/3_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/4_image/400.jpg",
                            "/images/ineffizienz/1_swiper/4_image/600.jpg",
                            "/images/ineffizienz/1_swiper/4_image/800.jpg",
                            "/images/ineffizienz/1_swiper/4_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/4_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/4_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/4_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/4_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/5_image/400.jpg",
                            "/images/ineffizienz/1_swiper/5_image/600.jpg",
                            "/images/ineffizienz/1_swiper/5_image/800.jpg",
                            "/images/ineffizienz/1_swiper/5_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/5_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/5_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/5_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/5_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/6_image/400.jpg",
                            "/images/ineffizienz/1_swiper/6_image/600.jpg",
                            "/images/ineffizienz/1_swiper/6_image/800.jpg",
                            "/images/ineffizienz/1_swiper/6_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/6_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/6_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/6_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/6_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/7_image/400.jpg",
                            "/images/ineffizienz/1_swiper/7_image/600.jpg",
                            "/images/ineffizienz/1_swiper/7_image/800.jpg",
                            "/images/ineffizienz/1_swiper/7_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/7_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/7_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/7_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/7_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/7_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/1_swiper/8_image/400.jpg",
                            "/images/ineffizienz/1_swiper/8_image/600.jpg",
                            "/images/ineffizienz/1_swiper/8_image/800.jpg",
                            "/images/ineffizienz/1_swiper/8_image/1000.jpg",
                            "/images/ineffizienz/1_swiper/8_image/1500.jpg",
                            "/images/ineffizienz/1_swiper/8_image/2000.jpg",
                            "/images/ineffizienz/1_swiper/8_image/3000.jpg",
                            "/images/ineffizienz/1_swiper/8_image/4000.jpg",
                            "/images/ineffizienz/1_swiper/8_image/5000.jpg"
                        ],
                    ]
            },

            // single 2
            {
                type: "single3D",
                srcset:
                    [
                        "/images/ineffizienz/2_single/400.jpg",
                        "/images/ineffizienz/2_single/600.jpg",
                        "/images/ineffizienz/2_single/800.jpg",
                        "/images/ineffizienz/2_single/1000.jpg",
                        "/images/ineffizienz/2_single/1500.jpg",
                        "/images/ineffizienz/2_single/2000.jpg",
                        "/images/ineffizienz/2_single/3000.jpg",
                        "/images/ineffizienz/2_single/4000.jpg",
                        "/images/ineffizienz/2_single/5000.jpg"
                    ]
            },

            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/ineffizienz/3_single/400.jpg",
                        "/images/ineffizienz/3_single/600.jpg",
                        "/images/ineffizienz/3_single/800.jpg",
                        "/images/ineffizienz/3_single/1000.jpg",
                        "/images/ineffizienz/3_single/1500.jpg",
                        "/images/ineffizienz/3_single/2000.jpg",
                        "/images/ineffizienz/3_single/3000.jpg",
                        "/images/ineffizienz/3_single/4000.jpg",
                        "/images/ineffizienz/3_single/5000.jpg"
                    ]
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/ineffizienz/4_swiper/1_image/400.jpg",
                            "/images/ineffizienz/4_swiper/1_image/600.jpg",
                            "/images/ineffizienz/4_swiper/1_image/800.jpg",
                            "/images/ineffizienz/4_swiper/1_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/1_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/1_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/1_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/1_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/2_image/400.jpg",
                            "/images/ineffizienz/4_swiper/2_image/600.jpg",
                            "/images/ineffizienz/4_swiper/2_image/800.jpg",
                            "/images/ineffizienz/4_swiper/2_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/2_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/2_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/2_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/2_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/3_image/400.jpg",
                            "/images/ineffizienz/4_swiper/3_image/600.jpg",
                            "/images/ineffizienz/4_swiper/3_image/800.jpg",
                            "/images/ineffizienz/4_swiper/3_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/3_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/3_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/3_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/3_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/4_image/400.jpg",
                            "/images/ineffizienz/4_swiper/4_image/600.jpg",
                            "/images/ineffizienz/4_swiper/4_image/800.jpg",
                            "/images/ineffizienz/4_swiper/4_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/4_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/4_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/4_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/4_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/5_image/400.jpg",
                            "/images/ineffizienz/4_swiper/5_image/600.jpg",
                            "/images/ineffizienz/4_swiper/5_image/800.jpg",
                            "/images/ineffizienz/4_swiper/5_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/5_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/5_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/5_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/5_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/6_image/400.jpg",
                            "/images/ineffizienz/4_swiper/6_image/600.jpg",
                            "/images/ineffizienz/4_swiper/6_image/800.jpg",
                            "/images/ineffizienz/4_swiper/6_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/6_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/6_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/6_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/6_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/ineffizienz/4_swiper/7_image/400.jpg",
                            "/images/ineffizienz/4_swiper/7_image/600.jpg",
                            "/images/ineffizienz/4_swiper/7_image/800.jpg",
                            "/images/ineffizienz/4_swiper/7_image/1000.jpg",
                            "/images/ineffizienz/4_swiper/7_image/1500.jpg",
                            "/images/ineffizienz/4_swiper/7_image/2000.jpg",
                            "/images/ineffizienz/4_swiper/7_image/3000.jpg",
                            "/images/ineffizienz/4_swiper/7_image/4000.jpg",
                            "/images/ineffizienz/4_swiper/7_image/5000.jpg"
                        ],
                    ],

                    background: "white"
            },

            // videos 9
            {
                type: "videoSmall",
                sources:
                    [
                        "/images/ineffizienz/9_video/1_video.mp4",
                        "/images/ineffizienz/9_video/2_video.mp4",
                    ]
            },

            // single 5
            {
                type: "single3D",
                srcset:
                    [
                        "/images/ineffizienz/5_single/400.jpg",
                        "/images/ineffizienz/5_single/600.jpg",
                        "/images/ineffizienz/5_single/800.jpg",
                        "/images/ineffizienz/5_single/1000.jpg",
                        "/images/ineffizienz/5_single/1500.jpg",
                        "/images/ineffizienz/5_single/2000.jpg",
                        "/images/ineffizienz/5_single/3000.jpg",
                        "/images/ineffizienz/5_single/4000.jpg",
                        "/images/ineffizienz/5_single/5000.jpg"
                    ]
            },

            // single 7
            {
                type: "single3D",
                srcset:
                    [
                        "/images/ineffizienz/7_single/400.jpg",
                        "/images/ineffizienz/7_single/600.jpg",
                        "/images/ineffizienz/7_single/800.jpg",
                        "/images/ineffizienz/7_single/1000.jpg",
                        "/images/ineffizienz/7_single/1500.jpg",
                        "/images/ineffizienz/7_single/2000.jpg",
                        "/images/ineffizienz/7_single/3000.jpg",
                        "/images/ineffizienz/7_single/4000.jpg",
                        "/images/ineffizienz/7_single/5000.jpg"
                    ]
            },

            // single 8
            {
                type: "single3D",
                srcset:
                    [
                        "/images/ineffizienz/8_single/400.jpg",
                        "/images/ineffizienz/8_single/600.jpg",
                        "/images/ineffizienz/8_single/800.jpg",
                        "/images/ineffizienz/8_single/1000.jpg",
                        "/images/ineffizienz/8_single/1500.jpg",
                        "/images/ineffizienz/8_single/2000.jpg",
                        "/images/ineffizienz/8_single/3000.jpg",
                        "/images/ineffizienz/8_single/4000.jpg",
                        "/images/ineffizienz/8_single/5000.jpg"
                    ]
            },

        ],
}