import { useFrame } from "@react-three/fiber"
import { useState } from "react"
import { Html } from "@react-three/drei";
import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import * as THREE from 'three';


export default function Physics_1({
    position = [0, 0, 0],
    scale,
    textures,
    geometries,
    materials
}) {
    let [strength, setStrength] = useState(2)

    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0}
            key={1}
        >

            {/* Floor */}
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={[scale[0], scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>

        </RigidBody>

        {/* Physics Cubes */}
        {[...Array(5)].map((_, yIndex) => ( /* Y */
            [...Array(2)].map((_, xIndex) => ( /* X */
                [...Array(3)].map((_, zIndex) => ( /* Z */
                    <RigidBody
                        mass={0.1}
                        restitution={0.9}
                        friction={0.5}
                        position={[0, 0, 0]}
                        key={`${xIndex}-${yIndex}-${zIndex}`}
                    >
                        <mesh
                            key={`${xIndex}-${yIndex}-${zIndex}`}
                            geometry={geometries.boxGeometry}
                            material={materials.defaultMaterial}
                            scale={[1, 1, 1]}
                            position={[xIndex - 1.5, yIndex + 0.5, zIndex - 1]}
                            receiveShadow
                            castShadow
                        />
                    </RigidBody>
                ))
            ))
        ))}

        {/* Blendings */}
        <mesh
            geometry={geometries.planeGeometry}
            scale={[10, 7, 0]}
            position={[0, 4, 4]}
            rotation-z={Math.PI * 0.5}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.physics_1}
            />
        </mesh>

        <Label
            position={position}
            scale={scale}
        />

    </group>
}

function Label({
    position,
    scale
}) {
    const [opacity, setOpacity] = useState(1)

    useFrame(({ clock }) => {
        let time = clock.getElapsedTime()
        setOpacity(Math.sin(time * 5) + 2 * 0.25)
    })

    return <Html style={
        {
            width: scale[0] * 60,
            transform: `translate(-${scale[0] * 60 * 0.5 + 300}px, -100px)`,
            opacity: opacity
        }}
        className="plattform__label"
    >

        Danger <br />
        Block

    </Html>
}