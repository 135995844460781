import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import { useState, useRef, useContext, useEffect } from 'react';
import { KeyboardContext } from '../Helpers/KeyboardContext'; 
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

import useGame from '../stores/useGame';

// Plattforms
import Repulsion_1 from './Repulsion_1';
import Attraction_1 from './Attraction_1';
import Physics_1 from './Physics_1';
import Physics_2 from './Physics_2';
import Repetition_1 from './Repetition_1';
import BreathPlattform from './BreathPlattform';
import Dynamic_1 from './Dynamic_1';

// Items
import Breath from '../items/Breath';


/* 
** Dynamic Plattform
*/

export function DynamicPlattform({ 
    dimension, 
    ropeMaterial,
    textures,
    geometries,
    materials
}) 
{
    const [isBreathVisible, setBreathVisible] = useState(true);

    /* Remove Items */
    const handleRemoveBreath = () => 
    {
        setBreathVisible(false);
    };
    
    const plattforms =
    {
        Ma: Prolongation,
        Je: Prolongation,
        ma: Prolongation,
        Me: HighFriction_1,
        Mei: Prolongation,
        Mau: Prolongation,
        fä: Prolongation,
        lä: Prolongation,
        La: Attraction_1,
        Fo: Attraction_1,
        la: Attraction_1,
        di: HighFriction_1,
        St: HighFriction_1,
        Le: HighFriction_1,
        At: HighFriction_1,
        Ha: Repulsion_1,
        das: Repulsion_1,
        di: Repulsion_1,
        ge: Repulsion_1,
        Ga: Physics_2,
        de: Physics_2,
        Go: Physics_1,
        da: Physics_1,
        die: Physics_1,
        Ge: Prolongation,
        Th: Physics_1,
        Wi: Repetition_1,
        Ab: Attraction_1,
        default: DefaultPlattform,

        // Satz Präsi
        Di: Repulsion_1,
        De: Physics_1,
        mei: Prolongation,
        we: Repetition_1,
        der: Repulsion_1,

        // To Do
        Je: Dynamic_1,
        Be: Physics_1,
    }

    /* Assign Plattforms to Ligatures */
    let PlattformType = plattforms[dimension.ligature] || plattforms['default']

    /* Check if Breath Plattform */
    if(dimension.isBreath) {
        PlattformType = NoPlattform
    }

    return <>

    {/* Plattform */}
        <PlattformType
            scale={[dimension.scale, 0.2, 4]}
            position={[dimension.position, 0, 0]}

            // Props
            textures={textures}
            geometries={geometries}
            materials={materials}
        />

    {/* Breath */}
        {isBreathVisible &&
            <Breath
                position={[dimension.position - dimension.scale * 0.5 + 0.8, 1, 0]}
                onRemove={handleRemoveBreath}

                textures={textures}
                geometries={geometries}
                materials={materials}
            />
        }
    </>
}


/* 
** Default Plattforms
*/


function DefaultPlattform({ 
    position = [0, 0, 0], 
    scale,
    geometries,
    materials
}) 
{
    return (
        <RigidBody 
            type='fixed'
            friction={0}
        >
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={scale}
                position={position}
                receiveShadow
            >
            </mesh>
        </RigidBody>
    );
}

function NoPlattform({
    position = [0, 0, 0],
    scale,
    geometries,
    materials
}) {
    return <></>
}


/* 
** Custom Plattforms
*/


function Prolongation({ 
    position = [0, 0, 0], 
    scale, 
    textures,
    geometries,
    materials
}) 
{
    const obstacle = useRef();

    // move Obstacle
    useFrame(({ clock }) => 
    { 
        let time = clock.getElapsedTime()
        let translationStrength = 5
        let x = Math.sin(time) * translationStrength
        obstacle.current.setNextKinematicTranslation({ x: position[0] + x, y: position[1], z: position[2] })
    })
    

    return <group position={position}>

    {/* Floor */}
        <RigidBody 
            type='fixed'
            friction={ 0 }
        >
        {/* 1 */}
            <mesh
                geometry={ geometries.boxGeometry }
                material={ materials.prolongationMaterial }
                scale={[scale[0] * 0.25, scale[1], scale[2]]}
                position={[ -scale[0] * 0.375, 0, 0 ] }
                receiveShadow
            >
            </mesh>
        {/* 2 */}
            <mesh
                geometry={ geometries.boxGeometry }
                material={materials.prolongationMaterial }
                scale={[scale[0] * 0.5, scale[1], scale[2]]}
                position={[ scale[0] * 0.25, 0, 0 ] }
                receiveShadow
            >
            </mesh>
        </RigidBody>

    {/* Obstacle */}
        <RigidBody
            type='kinematicPosition'
            friction={0}
            ref={obstacle}
        >
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.obstacleMaterial}
                scale={[1, 2, 2]}
                position={[0, 1.1, 0]}
                castShadow
            >
            </mesh>
        </RigidBody>

        {/* Blendings */}
        <mesh
            geometry={geometries.planeGeometry}
            scale={[15, 10, 0]}
            position={[0, 4, 8]}
        >
            <meshStandardMaterial 
                depthWrite={ false }
                blending={ THREE.CustomBlending }
                blendEquation={ THREE.MaxEquation }
                blendSrc={ THREE.SrcAlphaFactor }
                blendDst={ THREE.OneMinusSrcAlphaFactor }
                metalness={ 1.52 }
                map={textures.square_1}
            />
        </mesh>

    </group>
}


function HighFriction_1({ 
    position = [0, 0, 0], 
    scale, 
    textures,
    geometries,
    materials
}) 
{

    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0.8}
        >

            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={scale}
                receiveShadow
            >
            </mesh>

        </RigidBody>

        {/* Blendings */}
        <mesh
            geometry={geometries.planeGeometry}
            scale={[2, 2, 0]}
            position={[-scale[0] * 0.35, 1.5, 5]}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.friction_begin}
            />
        </mesh>
        <mesh
            geometry={geometries.planeGeometry}
            scale={[2, 2, 0]}
            position={[scale[0] * 0.35, 1.5, 5]}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.friction_end}
            />
        </mesh>
        <mesh
            geometry={geometries.planeGeometry}
            scale={[scale[0]-2, 2, 0]}
            position={[0, 1.5, 5]}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.friction_middle}
            />
        </mesh>

    </group>
}