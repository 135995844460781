import Navigation from './Navigation.jsx';
import Images from './Images.jsx';
import Welcome from './Welcome.jsx';
import WelcomeImages from './WelcomeImages.jsx';
import { log } from 'three/examples/jsm/nodes/Nodes.js';

export default function Project({data}) 
{
    return <>
        <Navigation data={ data }/>
        <Images data={ data }/>

        {
            data.main.page === "/aboutMe" &&
            <WelcomeImages />
        }
    </>
}