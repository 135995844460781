import { useEffect, useRef, useState } from "react"
import useSound from "use-sound";
import * as THREE from 'three'

import useGame from "../stores/useGame.jsx"
import { div } from "three/examples/jsm/nodes/Nodes.js"

export default function Ressources() 
{
    const [ breathLevel, setBreathLevel ] = useState(100)
    const [ anxietyLevel, setAnxietyLevel ] = useState(20)
    const [ breathLevelColor, setBreathLevelColor ] = useState('white')
    const [ anxietyLevelColor, setAnxietyLevelColor ] = useState('white')

    const playerIsSlow = useGame(state => state.playerIsSlow)
    const phase = useGame(state => state.phase)
    const itemBreathCount = useGame(state => state.itemBreathCount) 
    const restartGame = useGame(state => state.restartGame)
    const setDeathCount = useGame(state => state.setDeathCount)
    const deathCount = useGame(state => state.deathCount)
    const setGameLevel = useGame(state => state.setGameLevel)


    /* If Player is Slow, deacrease Breath Level */
    useEffect(() => 
    {
        let breathDecreaseInterval;

        if (playerIsSlow && phase === 'playing') 
        {
            breathDecreaseInterval = setInterval(() => 
            {
                setBreathLevel(prevBreathLevel => Math.max(0, Math.min(100, prevBreathLevel - 0.1)));
            }, 
            8);

            setBreathLevelColor('red')
        }
        else if (!playerIsSlow) 
        {
            setBreathLevelColor('white')
        }


        return () => { clearInterval(breathDecreaseInterval) };
    },
    [playerIsSlow, breathLevel])

    useEffect(() => 
    {
        /* BreathEnds */
        if (breathLevel <= 0) {
            restartGame()
            setDeathCount(deathCount + 1)
            setGameLevel(1)
        }

        /* Overlay */
        console.log(breathLevel)
    },
    [breathLevel])


    /* Calculate Anxiety */
    useEffect(() => 
    {
        let anxietyDecreaseInterval;

        if(phase === 'playing') 
        {
            anxietyDecreaseInterval = setInterval(() => 
            {
                setAnxietyLevel(prevBreathLevel => Math.max(0, Math.min(100, prevBreathLevel + 0.05)));
            },
            80);
        }
        if(phase === 'breathing') 
        {
            anxietyDecreaseInterval = setInterval(() => 
            {
                setAnxietyLevel(prevBreathLevel => Math.max(0, Math.min(100, prevBreathLevel + 0.05)));
            },
            80);
        }
        if(phase === "waiting") 
        {
            setAnxietyLevel(20)
        }

        return () => { clearInterval(anxietyDecreaseInterval) };
    },
    [phase])


    /* change color to green */
    useEffect(() => 
    {
        setBreathLevel(breathLevel > 90 ? breathLevel + (100 -  breathLevel) : breathLevel + 5);
        setBreathLevelColor('rgb(145, 145, 235)');

        const timeoutId = setTimeout(() => {
            setBreathLevelColor('white');
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, 
    [itemBreathCount]);


    /* Reset Levels when died */
    useEffect(() => 
    {
        setBreathLevel(100)
    },
    [deathCount])


    return <div className={ `ressources ${ (phase === 'playing' || phase === 'breathing') ? "ressources--visible" : "" }` }>

            <CircleBreath level={ breathLevel } strokeColor={ breathLevelColor } />
            <CircleBrain level={ anxietyLevel } strokeColor="white" />
            <Progress />

            <BreathOverlay breathLevel={ breathLevel }/>

        </div>
}

function BreathOverlay({ breathLevel }) 
{
    console.log(breathLevel)
    return (
        <div 
            className="ressources__breathOverlay"
            style={{
                opacity: (100 - breathLevel * 2) / 100
            }}
            >
        </div>
    )
}

function CircleBreath({ level, strokeColor })
{
    const circumference = 2 * Math.PI * 50; // Assuming radius is 50
    const strokeDashoffset = circumference - (level / 100) * circumference;

    return (
        <div className="ressources__breath">
            <svg className="breath__svg">

                {/* Outer circle */}
                <circle 
                    r="56" 
                    fill="black" 
                    stroke="white" 
                    strokeWidth="1.5" 
                />

                {/* inner circle */}
                <circle 
                    r="44" 
                    fill="none" 
                    stroke="white" 
                    strokeWidth="1.5" 
                />

                <g transform="rotate(-90)">
                    <circle
                        r="50"
                        fill="transparent"
                        stroke={strokeColor}
                        strokeWidth="12"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                    />
                </g>
                
            </svg>
            <div className='breath__bubbles'>
                5
            </div>
        </div>
    );
};

function CircleBrain({ level, strokeColor }) {
    const circumference = 2 * Math.PI * 50; // Assuming radius is 50
    const strokeDashoffset = circumference - (level / 100) * circumference;

    return (
        <div className="ressources__breath">
            <svg className="breath__svg">

                {/* Outer circle */}
                <circle
                    r="56"
                    fill="black"
                    stroke="white"
                    strokeWidth="1.5"
                />

                {/* inner circle */}
                <circle
                    r="44"
                    fill="none"
                    stroke="white"
                    strokeWidth="1.5"
                />

                <g transform="rotate(-90)">
                    <circle
                        r="50"
                        fill="transparent"
                        stroke={strokeColor}
                        strokeWidth="12"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                    />
                </g>

            </svg>
            <div className='brain__bubbles'>
                6
            </div>
        </div>
    );
};


function Progress() 
{
    const mainTextCurrentWord = useGame(state => state.mainTextCurrentWord)
    const phase = useGame(state => state.phase)
    const [ progressCount, setProgressCount ]  = useState(0)
    const [ width, setWidth ] = useState(100)

    useEffect(() => {
        if (mainTextCurrentWord !== undefined) 
        {
            setProgressCount(mainTextCurrentWord.id)
        }
    },
        [mainTextCurrentWord])

    useEffect(() => 
    {
        if(progressCount >= 10) 
        {
            setWidth(160)
        } else 
        {
            setWidth(100)
        }
    },
    [progressCount])

    return <div className="ressources__progress"> 

        <svg 
            className="progress__svg"
            width="100"
            height="100"
        >

            { phase === "breathing" &&
                <rect
                    width={width}
                    height="100"
                    fill="black"
                />
            }

            <line 
                x1="0" y1="85" 
                x2={ width } y2="85" 
                stroke="white" strokeWidth="1.5" 
            />

            <line 
                x1="0" y1="100" 
                x2={ width } y2="100" 
                stroke="white" strokeWidth="1.5" 
            />

            <line 
                x1="0" y1="100" 
                x2="0" y2="0" 
                stroke="white" strokeWidth="1.5" 
            />

            <line 
                x1={ width } y1="100" 
                x2={ width } y2="0" 
                stroke="white" strokeWidth="1.5" 
            />


        </svg>

        <div 
            className="progress__number"
            style={{ width: `${ width }px` }}
        >
            {progressCount} 
        </div>
        
        </div>
}




