import { useEffect } from 'react'
import Swiper from 'swiper'
import * as lazysizes from "lazysizes";
import gsap from 'gsap';
import { useState } from 'react';
import useGlobal from '../stores/useGlobal.jsx';
import Lve from './Lve.jsx';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

import 'swiper/swiper-bundle.css';
import '../style/images.css'
import '../style/sizes.css'

export default function Images({ data }) 
{
    const windowSmall = useGlobal(state => state.windowSmall)
    const isometriesOpen = useGlobal(state => state.isometriesOpen)

    return <div 
        className="images" 
        key={data}
        style={{ display: (windowSmall && isometriesOpen) ? 'none' : 'block' }}
    >

    {/* Render Ansichten */}
        { data.sources[0].type === "ansichten" &&
            <div 
                className="images__ansichten"
            >
                <img 
                    alt="" 
                    className="ansichten__image lazyload" 
                    data-sizes="auto"
                    data-src={data.sources[0].srcset[8]} 

                    data-srcset={`
                        ${data.sources[0].srcset[0]} 400w,
                        ${data.sources[0].srcset[1]} 600w,
                        ${data.sources[0].srcset[2]} 800w,
                        ${data.sources[0].srcset[3]} 1000w,
                        ${data.sources[0].srcset[4]} 1500w,
                        ${data.sources[0].srcset[5]} 2000w,
                        ${data.sources[0].srcset[6]} 3000w,
                        ${data.sources[0].srcset[7]} 4000w
                        ${data.sources[0].srcset[8]} 5000w
                    `}
                    />

            </div>
        }

    {/* About Me */}
        { data.skills && <Skills skills={ data.skills } /> }

        <div className="images__text">
            <div className="text__adjectives">

                { data.text.adjectives.map((adjective, index) => 
                {
                    return <div className="adjectives__adjective" key={ index }>
                        <p className="adjective__de">{ adjective.de }</p>
                        <p className="adjective__en">{ adjective.en }</p>
                    </div>
                }) }

            </div>

            <p
                className="text__content"
                dangerouslySetInnerHTML={{ __html: data.text.content.de }}
            ></p>
            {/* <p className="text__content">
                    {data.text.content.en}
            </p> */}
        </div>

    {/* love */}
        <Lve data={data} />


    {/* Render different types of images */}
        {data.sources.map((source, index) => 
        {
            // render Swiper
            if (source.type === "swiper" || source.type === "swiperFullScreen") 
            {
                return (
                <>
                    <swiper-container 
                        className="images__swiper" 
                        key={index}
                        navigation="true"
                        scrollbar-clickable="true"
                        effect="fade"
                        autoplay-delay="2000"
                        
                        style={{ 
                            backgroundColor: source.background ? `${ source.background }` : "rgb(200,200,200)",
                        }}

                        navigation-next-el={`.swiper__navigationRight--${ index }`}
                        navigation-prev-el={`.swiper__navigationLeft--${index}`}
                    >
                        {
                            source.srcset.map((srcset, i) => 
                            (
                                <swiper-slide 
                                    className={`swiper__slide`}
                                    lazy="true"
                                    key={i}
                                >
                                    <img 
                                        data-sizes="auto"
                                        data-src={srcset[8]}
                                        data-srcset={`
                                        ${srcset[0]} 400w,
                                        ${srcset[1]} 600w,
                                        ${srcset[2]} 800w,
                                        ${srcset[3]} 1000w,
                                        ${srcset[4]} 1500w,
                                        ${srcset[5]} 2000w,
                                        ${srcset[6]} 3000w,
                                        ${srcset[7]} 4000w
                                        ${srcset[8]} 5000w
                                        `}

                                        style={{
                                            maxWidth: source.maxWidth ? `${source.maxWidth}` : "auto"
                                        }}

                                        alt=""
                                        className={`slide__image ${source.type === "swiperFullScreen" ? "slide__image--fullScreen" : "" } lazyload`}
                                        loading='lazy'
                                    />
                                </swiper-slide>
                            ))
                        }

                        <div className={`swiper__navigationLeft swiper__navigationLeft--${index}`}></div>
                        <div className={`swiper__navigationRight swiper__navigationRight--${index}`}></div>

                    </swiper-container>


                </>
                )
            } 

            // render 2D Single
                else if (source.type === "single2D") 
            {
                return (
                    <div 
                        className="images__single2D" 
                        key={index}
                        style={{ 
                            backgroundColor: source.background ? `${source.background}` : "rgb(200,200,200)",
                            padding: source.padding ? `${source.padding}` : "0" 
                        }}
                    >
                        <img 
                            src={source.srcset[1]} 
                            alt={source.src}
                            data-sizes="auto"
                            className="slide__image lazyload" 
                            data-srcset={`
                            ${source.srcset[0]} 400w,
                            ${source.srcset[1]} 600w,
                            ${source.srcset[2]} 800w,
                            ${source.srcset[3]} 1000w,
                            ${source.srcset[4]} 1500w,
                            ${source.srcset[5]} 2000w,
                            ${source.srcset[6]} 3000w,
                            ${source.srcset[7]} 4000w
                            ${source.srcset[8]} 5000w
                            `}
                        />
                    </div>
                );
            }

            // render Double
                else if (source.type === "double") 
            {
                return (
                    <div 
                        className="images__double" 
                        key={index}
                        style={{ 
                            backgroundColor: source.background ? `${source.background}` : "rgb(200,200,200)",
                            padding: source.padding ? `${source.padding}` : "0" 
                        }}
                    >
                        <img 
                            src={source.srcset[0][8]} 
                            alt={source.src}
                            className="double__image--right" 
                        />
                        <img 
                            src={source.srcset[1][8]} 
                            alt={source.src}
                            className="double__image--left" 
                        />
                    </div>
                );
            }

            // render 3D Single
                else if (source.type === "single3D") 
            {
                return (
                    <div 
                        className="images__single3D" 
                        key={index}
                    >
                        <img
                            src={source.srcset[1]}
                            alt={source.src}
                            data-sizes="auto"
                            className="single3D__image lazyload"
                            data-srcset={`
                            ${source.srcset[0]} 400w,
                            ${source.srcset[1]} 600w,
                            ${source.srcset[2]} 800w,
                            ${source.srcset[3]} 1000w,
                            ${source.srcset[4]} 1500w,
                            ${source.srcset[5]} 2000w,
                            ${source.srcset[6]} 3000w,
                            ${source.srcset[7]} 4000w
                            ${source.srcset[8]} 5000w
                            `}
                        />
                    </div>
                );
            }

            // render videos Small
                else if (source.type === "videoSmall")
            {
                return (
                    <div className="images__videoSmall" key={index}>
                            {source.sources.map((src, i) => (
                                <video
                                    key={i}
                                    src={src}
                                    alt={src}
                                    className="videoSmall__video"
                                    autoPlay
                                    loop
                                    controls
                                />
                            ))}
                    </div>
                );
            }

            // render videos Big
                else if (source.type === "videoBig") 
            {
                return (
                    <div className="images__videoBig" key={index}>
                        <video
                            key={1}
                            src={source.source}
                            alt={source.source}
                            className="videoBig__video"
                            autoPlay
                            loop
                            controls
                        />
                    </div>
                );
            }

            // none
                else if (source.type === "none") 
            {
                return (
                    <div 
                        className="images__none" 
                        key={index}
                    >
                    </div>
                );
            }

            // render null
                else 
            {
                return null
            }
        })}

    </div>
}


function Skills({ skills }) 
{
    const circumference = 2 * Math.PI * 50; // Assuming radius is 50

    return <div className="skills">

        <div className="skills__title">Programms</div>
        <div className="skills__programms">
            {
            skills.programms.map(programm => (
                <div className="programms__programm" key={programm.title}>

                    <div
                        className="programm__title"
                        dangerouslySetInnerHTML={{ __html: programm.title }}
                    ></div>


                    <svg className="programm__svg">

                        {/* Outer circle */}
                        <circle
                            cx="56"
                            cy="56"
                            r="56"
                            fill="transparent"
                            stroke="#00ffa0"
                            strokeWidth="1.5"
                        />

                        {/* inner circle */}
                        <circle
                            cx="56"
                            cy="56"
                            r="44"
                            fill="none"
                            stroke="#00ffa0"
                            strokeWidth="1.5"
                        />

                            <circle
                                cx="56"
                                cy="56"
                                r="50"
                                fill="transparent"
                                stroke={programm.developing ? "rgb(255, 0, 255)" : "#00ffa0"}
                                strokeWidth="12"
                                strokeDasharray={circumference}
                                strokeDashoffset={circumference - (programm.level / 100) * circumference }
                            />

                    </svg>

                </div>
            ))}
        </div>

        <div className="skills__title">Languages</div>
        <div className="skills__programms">
            {
            skills.languages.map(programm => (
                <div className="programms__programm" key={programm.title}>

                    <div
                        className="programm__title"
                        dangerouslySetInnerHTML={{ __html: programm.title }}
                    ></div>


                    <svg className="programm__svg">

                        {/* Outer circle */}
                        <circle
                            cx="56"
                            cy="56"
                            r="56"
                            fill="transparent"
                            stroke="#00ffa0"
                            strokeWidth="1.5"
                        />

                        {/* inner circle */}
                        <circle
                            cx="56"
                            cy="56"
                            r="44"
                            fill="none"
                            stroke="#00ffa0"
                            strokeWidth="1.5"
                        />

                        <circle
                            cx="56"
                            cy="56"
                            r="50"
                            fill="transparent"
                            stroke={programm.developing ? "rgb(255, 0, 255)" : "#00ffa0" }
                            strokeWidth="12"
                            strokeDasharray={circumference}
                            strokeDashoffset={circumference - (programm.level / 100) * circumference }
                        />

                    </svg>

                </div>
            ))}
        </div>

    </div>
}