import { RigidBody } from "@react-three/rapier"
import * as THREE from 'three'
import useGame from "../stores/useGame"

export default function StartPlattform({ 
    position = [-4, 0, 0], 
    scale,
    textures,
    geometries,
    materials,
}) 
{
    const phase = useGame(state => state.phase)

    return <group>
        <RigidBody
            type='fixed'
            friction={0}
        >
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={[4, 0.2, 4]}
                position={ [-4, 0, 0] }
                receiveShadow
            >
            </mesh>
        </RigidBody>

        {phase === "waiting" &&
            <Blending
                textures={textures}
                planeGeometry={geometries.planeGeometry}
                position={position}
            />
        }

    </group>
}


function Blending({
    textures,
    planeGeometry,
    position
}) {
    return <>
        <mesh
            geometry={planeGeometry}
            scale={[7, 7, 0]}
            position={ [ position[0] + 3, position[1] + 2, position[2] ] }
            rotation={ [ 0, -Math.PI * 0.5, 0] }
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.triangle_1}
            />
        </mesh>
        <mesh
            geometry={planeGeometry}
            scale={[10, 10, 0]}
            position={ [ position[0] - 3, position[1] + 3, position[2] ] }
            rotation={ [ 0, -Math.PI * 0.5, 0] }
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.circle_1}
            />
        </mesh>
    </>
}