export const nachtDerBibliotheken =
{
    main:
    {
        title: "Nacht der Bibliotheken",
        category: "Corporate Design",
        page: "/nachtDerBibliotheken"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙1134 &times; 216 <br> ∙895 &times; 1280" },
            { title: "year", content: "∙2024" },
            { title: "products", content: "∙Leporello <br> ∙Poster <br> ∙Social Media <br> ∙Logo <br> ∙Website" },
            { title: "printing", content: "∙offset" },
        ],

    text:
    {
        adjectives:
            [
                { de: "Labyrinth", en: "Labyrinth" },
                { de: "Räume", en: "Spaces" },
                { de: "Träume", en: "Dreams" },
                { de: "surreal", en: "surreal" },
                { de: "Menschheit", en: "Humanity" },
            ],

        content:
        {
            de: `
            Die ‹Nacht der Bibliotheken› ist ein Luzerner Event von fünf teilnehmenden Bibliotheken, welcher den Wert dieser und dessen Wissens- und Fantasieschatz feiern soll. Das Hauptelement des CD’s ist die surreale, üppige und räumliche Bildwelt, in der sich moderne Menschen mit Uhrzeitmenschen auf diversen Evolutionsstufen austauschen und aufhalten. Es ist ein reichhaltiges Corporate Design entstanden, welches inspiriert, flexibel einsetzbar ist und doch einen hohen Grad an wiedererkennbarkeit aufweist. Jedes Element kann dabei begründet werden und ist semantisch aufgeladen.
            ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/0_ansichten/400.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/600.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/800.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/1000.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/1500.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/2000.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/3000.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/4000.jpg",
                        "/images/nachtDerBibliotheken/0_ansichten/5000.jpg"
                    ]
            },

            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/1_single/400.jpg",
                        "/images/nachtDerBibliotheken/1_single/600.jpg",
                        "/images/nachtDerBibliotheken/1_single/800.jpg",
                        "/images/nachtDerBibliotheken/1_single/1000.jpg",
                        "/images/nachtDerBibliotheken/1_single/1500.jpg",
                        "/images/nachtDerBibliotheken/1_single/2000.jpg",
                        "/images/nachtDerBibliotheken/1_single/3000.jpg",
                        "/images/nachtDerBibliotheken/1_single/4000.jpg",
                        "/images/nachtDerBibliotheken/1_single/5000.jpg"
                    ]
            },

            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/2_single/400.jpg",
                        "/images/nachtDerBibliotheken/2_single/600.jpg",
                        "/images/nachtDerBibliotheken/2_single/800.jpg",
                        "/images/nachtDerBibliotheken/2_single/1000.jpg",
                        "/images/nachtDerBibliotheken/2_single/1500.jpg",
                        "/images/nachtDerBibliotheken/2_single/2000.jpg",
                        "/images/nachtDerBibliotheken/2_single/3000.jpg",
                        "/images/nachtDerBibliotheken/2_single/4000.jpg",
                        "/images/nachtDerBibliotheken/2_single/5000.jpg"
                    ]
            },

            // videos 3
            {
                type: "videoBig",
                source: "/images/nachtDerBibliotheken/3_video/1_video.mp4"
            },

            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/5_single/400.jpg",
                        "/images/nachtDerBibliotheken/5_single/600.jpg",
                        "/images/nachtDerBibliotheken/5_single/800.jpg",
                        "/images/nachtDerBibliotheken/5_single/1000.jpg",
                        "/images/nachtDerBibliotheken/5_single/1500.jpg",
                        "/images/nachtDerBibliotheken/5_single/2000.jpg",
                        "/images/nachtDerBibliotheken/5_single/3000.jpg",
                        "/images/nachtDerBibliotheken/5_single/4000.jpg",
                        "/images/nachtDerBibliotheken/5_single/5000.jpg"
                    ]
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/nachtDerBibliotheken/4_swiper/1_image/400.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/600.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/800.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/1000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/1500.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/2000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/3000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/4000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/nachtDerBibliotheken/4_swiper/2_image/400.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/600.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/800.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/1000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/1500.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/2000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/3000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/4000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/nachtDerBibliotheken/4_swiper/3_image/400.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/600.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/800.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/1000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/1500.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/2000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/3000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/4000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/nachtDerBibliotheken/4_swiper/4_image/400.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/600.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/800.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/1000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/1500.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/2000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/3000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/4000.jpg",
                            "/images/nachtDerBibliotheken/4_swiper/4_image/5000.jpg"
                        ],
                    ],
                maxWidth: "1500px",
                background: "black"
            },
            
            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/7_single/400.jpg",
                        "/images/nachtDerBibliotheken/7_single/600.jpg",
                        "/images/nachtDerBibliotheken/7_single/800.jpg",
                        "/images/nachtDerBibliotheken/7_single/1000.jpg",
                        "/images/nachtDerBibliotheken/7_single/1500.jpg",
                        "/images/nachtDerBibliotheken/7_single/2000.jpg",
                        "/images/nachtDerBibliotheken/7_single/3000.jpg",
                        "/images/nachtDerBibliotheken/7_single/4000.jpg",
                        "/images/nachtDerBibliotheken/7_single/5000.jpg"
                    ]
            },
            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/nachtDerBibliotheken/6_single/400.jpg",
                        "/images/nachtDerBibliotheken/6_single/600.jpg",
                        "/images/nachtDerBibliotheken/6_single/800.jpg",
                        "/images/nachtDerBibliotheken/6_single/1000.jpg",
                        "/images/nachtDerBibliotheken/6_single/1500.jpg",
                        "/images/nachtDerBibliotheken/6_single/2000.jpg",
                        "/images/nachtDerBibliotheken/6_single/3000.jpg",
                        "/images/nachtDerBibliotheken/6_single/4000.jpg",
                        "/images/nachtDerBibliotheken/6_single/5000.jpg"
                    ]
            },


        ],
}