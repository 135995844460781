export const jungeGrafik =
{
    main:
    {
        title: "Animate FG and JG",
        category: "Animation&#8198;/&#8198;Poster",
        page: "/jungeGrafik"
    },

    isometrie:
    {
        type: ".gif",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "customer", content: "∙Fachklasse Grafik <br> ∙Junge Grafik" },
            { title: "proportions", content: "∙420 &times; 594 <br> ∙digital" },
            { title: "years", content: "∙2022 <br> ∙2023" },
            { title: "printing", content: "∙Offset printing" },
            { title: "edition", content: "∙2000 copies <br> ∙25 Interviews" },
        ],

    text:
    {
        adjectives:
            [
                { de: "verspielt", en: "playful " },
                { de: "umblättern", en: "turn over" },
                { de: "Neuausrichtung", en: "Reorientation " },
                { de: "Erzählung", en: "Narrative" },
                { de: "flüssig", en: "smooth" },
            ],

        content:
        {
            de: `
            Beim ersten Auftrag von der Fachklasse Grafik Luzern ging es um eine Werbung für die Abschlussfeier, sowie um die Gestaltung dazugehöriger Animationen für Instagram. <br>
            Der zweite Auftrag, den ich hier dem ersten gegenüberstelle kam vom Junge Grafik Award. Hier bin ich dafür zuständig, dass das Corporate Design auf verschiedenen Anewendungen animiert wird.
            ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/jungeGrafik/0_ansichten/400.jpg",
                        "/images/jungeGrafik/0_ansichten/600.jpg",
                        "/images/jungeGrafik/0_ansichten/800.jpg",
                        "/images/jungeGrafik/0_ansichten/1000.jpg",
                        "/images/jungeGrafik/0_ansichten/1500.jpg",
                        "/images/jungeGrafik/0_ansichten/2000.jpg",
                        "/images/jungeGrafik/0_ansichten/3000.jpg",
                        "/images/jungeGrafik/0_ansichten/4000.jpg",
                        "/images/jungeGrafik/0_ansichten/5000.jpg"
                    ]
            },

            // videos 3
            {
                type: "videoSmall",
                sources:
                    [
                        "/images/jungeGrafik/6_video/1_video.mp4",
                        "/images/jungeGrafik/6_video/2_video.mp4",
                        "/images/jungeGrafik/6_video/3_video.mp4"
                    ]
            },

            // single 4
            {
                type: "single2D",
                srcset:
                    [
                        "/images/jungeGrafik/5_single/400.jpg",
                        "/images/jungeGrafik/5_single/600.jpg",
                        "/images/jungeGrafik/5_single/800.jpg",
                        "/images/jungeGrafik/5_single/1000.jpg",
                        "/images/jungeGrafik/5_single/1500.jpg",
                        "/images/jungeGrafik/5_single/2000.jpg",
                        "/images/jungeGrafik/5_single/3000.jpg",
                        "/images/jungeGrafik/5_single/4000.jpg",
                        "/images/jungeGrafik/5_single/5000.jpg"
                    ],
                background: "white"
            },

            // videos 3
            {
                type: "videoBig",
                source: "/images/jungeGrafik/7_video/1_video.mp4"
            },

            // single 1
            {
                type: "single2D",
                srcset:
                    [
                        "/images/jungeGrafik/1_single/400.jpg",
                        "/images/jungeGrafik/1_single/600.jpg",
                        "/images/jungeGrafik/1_single/800.jpg",
                        "/images/jungeGrafik/1_single/1000.jpg",
                        "/images/jungeGrafik/1_single/1500.jpg",
                        "/images/jungeGrafik/1_single/2000.jpg",
                        "/images/jungeGrafik/1_single/3000.jpg",
                        "/images/jungeGrafik/1_single/4000.jpg",
                        "/images/jungeGrafik/1_single/5000.jpg"
                    ],
            },

            // videos 3
            {
                type: "videoSmall",
                sources:
                    [
                        "/images/jungeGrafik/3_video/1_video.mp4",
                        "/images/jungeGrafik/3_video/2_video.mp4",
                        "/images/jungeGrafik/3_video/3_video.mp4",
                    ]
            },
            
            // single 2
            {
                type: "single2D",
                srcset:
                    [
                        "/images/jungeGrafik/2_single/400.jpg",
                        "/images/jungeGrafik/2_single/600.jpg",
                        "/images/jungeGrafik/2_single/800.jpg",
                        "/images/jungeGrafik/2_single/1000.jpg",
                        "/images/jungeGrafik/2_single/1500.jpg",
                        "/images/jungeGrafik/2_single/2000.jpg",
                        "/images/jungeGrafik/2_single/3000.jpg",
                        "/images/jungeGrafik/2_single/4000.jpg",
                        "/images/jungeGrafik/2_single/5000.jpg"
                    ],
            },

                        // videos 3
            {
                type: "videoSmall",
                sources: 
                [
                    "/images/jungeGrafik/3_video/4_video.mp4",
                    "/images/jungeGrafik/3_video/5_video.mp4",
                    "/images/jungeGrafik/3_video/6_video.mp4"
                ]
            },


            // single 4
            {
                type: "single2D",
                srcset:
                    [
                        "/images/jungeGrafik/4_single/400.jpg",
                        "/images/jungeGrafik/4_single/600.jpg",
                        "/images/jungeGrafik/4_single/800.jpg",
                        "/images/jungeGrafik/4_single/1000.jpg",
                        "/images/jungeGrafik/4_single/1500.jpg",
                        "/images/jungeGrafik/4_single/2000.jpg",
                        "/images/jungeGrafik/4_single/3000.jpg",
                        "/images/jungeGrafik/4_single/4000.jpg",
                        "/images/jungeGrafik/4_single/5000.jpg"
                    ],
                    background: "white"
            },

            // videos 3
            {
                type: "videoBig",
                source: "/images/jungeGrafik/8_video/1_video.mp4"
            },
        ],
}