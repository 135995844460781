import React, { useState, useEffect } from 'react';
import { log } from 'three/examples/jsm/nodes/Nodes.js';

import '../style/welcome.css'

export default function WelcomeImages() {
    const [imageCount, setImageCount] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => 
    {
        // Fetch the number from the text file
        fetch('/welcome/var_str.txt')
            .then(response => response.text())
            .then(data => {
                const count = parseInt(data);
                setImageCount(count);
            })
            .catch(error => console.error('Error fetching number:', error));
    }, []);


    return (
        <div className="welcomeImages">
                {Array.from({ length: imageCount }, (_, index) => (
                    <div className="welcomeImages__grid">
                        <img
                            key={index}
                            className='grid__image'
                            src = {`/welcome/images/${ index.toString().padStart(3, '0') }.png`}
                            alt={`Image ${index + 1}`}
                        />
                    </div>
                ))}
        </div>

    );
}
