import { useFrame } from "@react-three/fiber"
import { useState, useRef } from "react"
import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import * as THREE from 'three';


export default function Repetition_1({
    position = [0, 0, 0],
    scale,
    textures,
    geometries,
    materials
}) {

    useFrame(({ clock }) => 
    {
        let time = clock.getElapsedTime()
        let translationStrength = 5
        let x = Math.sin(time) * translationStrength
        // obstacle.current.setNextKinematicTranslation({ x: position[0] + x, y: position[1], z: position[2] })
    })

    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0}
        >

            {/* Floor Cubes */}
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ -scale[0] * 0.5, 0, 0 ]}
                scale={[1.7, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ -scale[0] * 0.167, 0, 0 ]}
                scale={[1.7, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ -scale[0] * -0.167, 0, 0 ]}
                scale={[1.7, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ -scale[0] * -0.5, 0, 0 ]}
                scale={[1.7, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>

        </RigidBody>

        {/* Blendings */}
        <mesh
            geometry={geometries.planeGeometry}
            scale={[15, 10.5, 0]}
            position={[0, 4, 5]}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.repetition_1}
            />
        </mesh>

    </group>
}
