export const music =
{
    main:
    {
        title: "Music",
        category: "Illustration&#8198;/&#8198;creative coding",
        page: "/music"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙150 &times; 220 <br> ∙594 &times; 420 <br> ∙895  &times; 1280 " },
            { title: "year", content: "∙2021 <br> ∙2022" },
            { title: "weight", content: "∙55 g <br> ∙24 g <br> ∙23 g" },
            { title: "printing", content: "∙digital <br> ∙Laser" },
            { title: "binding", content: "∙Thread stitching <br> ∙and no binding" },
        ],

    text:
    {
        adjectives:
            [
                { de: "isometrisch", en: "isometric" },
                { de: "analytisch", en: "analytic" },
                { de: "Formen", en: "Shapes" },
                { de: "Mikrokosmos", en: "Microcosmos" },
                { de: "Generativ", en: "generative" },
            ],

        content:
        {
            de: `Stück für Stück nahm ich diesen Walkman auseinander und erstellte eine visuelle Bestandsaufnahme. In diesem Projekt entdeckte ich für mich das Potenzial isometrischer Darstellungen. Nachdem der Walkman konstruiert wurde, nahm ich ihn wieder visuell auseinander und erstellte neue Kombinationen mit dessen Einzelteile. Der Fundus der entstandenen Formen bietet die Grundlage für meine Schrift ‹Quasar Stereo Pro›. Die entstandenen Resultate habe ich in A2 geplottet und in einer kleinen Broschüre zusammengefasst.` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/music/0_ansichten/400.jpg",
                        "/images/music/0_ansichten/600.jpg",
                        "/images/music/0_ansichten/800.jpg",
                        "/images/music/0_ansichten/1000.jpg",
                        "/images/music/0_ansichten/1500.jpg",
                        "/images/music/0_ansichten/2000.jpg",
                        "/images/music/0_ansichten/3000.jpg",
                        "/images/music/0_ansichten/4000.jpg",
                        "/images/music/0_ansichten/5000.jpg"
                    ]
            },

            // single 1
            {
                type: "single2D",
                srcset:
                    [
                        "/images/music/1_single/400.jpg",
                        "/images/music/1_single/600.jpg",
                        "/images/music/1_single/800.jpg",
                        "/images/music/1_single/1000.jpg",
                        "/images/music/1_single/1500.jpg",
                        "/images/music/1_single/2000.jpg",
                        "/images/music/1_single/3000.jpg",
                        "/images/music/1_single/4000.jpg",
                        "/images/music/1_single/5000.jpg"
                    ],
                background: "white"
            },

            // swiper 2
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/music/2_swiper/1_image/400.jpg",
                            "/images/music/2_swiper/1_image/600.jpg",
                            "/images/music/2_swiper/1_image/800.jpg",
                            "/images/music/2_swiper/1_image/1000.jpg",
                            "/images/music/2_swiper/1_image/1500.jpg",
                            "/images/music/2_swiper/1_image/2000.jpg",
                            "/images/music/2_swiper/1_image/3000.jpg",
                            "/images/music/2_swiper/1_image/4000.jpg",
                            "/images/music/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/music/2_swiper/2_image/400.jpg",
                            "/images/music/2_swiper/2_image/600.jpg",
                            "/images/music/2_swiper/2_image/800.jpg",
                            "/images/music/2_swiper/2_image/1000.jpg",
                            "/images/music/2_swiper/2_image/1500.jpg",
                            "/images/music/2_swiper/2_image/2000.jpg",
                            "/images/music/2_swiper/2_image/3000.jpg",
                            "/images/music/2_swiper/2_image/4000.jpg",
                            "/images/music/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/music/2_swiper/3_image/400.jpg",
                            "/images/music/2_swiper/3_image/600.jpg",
                            "/images/music/2_swiper/3_image/800.jpg",
                            "/images/music/2_swiper/3_image/1000.jpg",
                            "/images/music/2_swiper/3_image/1500.jpg",
                            "/images/music/2_swiper/3_image/2000.jpg",
                            "/images/music/2_swiper/3_image/3000.jpg",
                            "/images/music/2_swiper/3_image/4000.jpg",
                            "/images/music/2_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/music/2_swiper/4_image/400.jpg",
                            "/images/music/2_swiper/4_image/600.jpg",
                            "/images/music/2_swiper/4_image/800.jpg",
                            "/images/music/2_swiper/4_image/1000.jpg",
                            "/images/music/2_swiper/4_image/1500.jpg",
                            "/images/music/2_swiper/4_image/2000.jpg",
                            "/images/music/2_swiper/4_image/3000.jpg",
                            "/images/music/2_swiper/4_image/4000.jpg",
                            "/images/music/2_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/music/2_swiper/5_image/400.jpg",
                            "/images/music/2_swiper/5_image/600.jpg",
                            "/images/music/2_swiper/5_image/800.jpg",
                            "/images/music/2_swiper/5_image/1000.jpg",
                            "/images/music/2_swiper/5_image/1500.jpg",
                            "/images/music/2_swiper/5_image/2000.jpg",
                            "/images/music/2_swiper/5_image/3000.jpg",
                            "/images/music/2_swiper/5_image/4000.jpg",
                            "/images/music/2_swiper/5_image/5000.jpg"
                        ],
                    ]
            },

            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/music/3_single/400.jpg",
                        "/images/music/3_single/600.jpg",
                        "/images/music/3_single/800.jpg",
                        "/images/music/3_single/1000.jpg",
                        "/images/music/3_single/1500.jpg",
                        "/images/music/3_single/2000.jpg",
                        "/images/music/3_single/3000.jpg",
                        "/images/music/3_single/4000.jpg",
                        "/images/music/3_single/5000.jpg"
                    ],
            },

            // single 4
            {
                type: "single2D",
                srcset:
                    [
                        "/images/music/4_single/400.jpg",
                        "/images/music/4_single/600.jpg",
                        "/images/music/4_single/800.jpg",
                        "/images/music/4_single/1000.jpg",
                        "/images/music/4_single/1500.jpg",
                        "/images/music/4_single/2000.jpg",
                        "/images/music/4_single/3000.jpg",
                        "/images/music/4_single/4000.jpg",
                        "/images/music/4_single/5000.jpg"
                    ],
                background: "white"
            },

            // swiper 5
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/music/5_swiper/1_image/400.jpg",
                            "/images/music/5_swiper/1_image/600.jpg",
                            "/images/music/5_swiper/1_image/800.jpg",
                            "/images/music/5_swiper/1_image/1000.jpg",
                            "/images/music/5_swiper/1_image/1500.jpg",
                            "/images/music/5_swiper/1_image/2000.jpg",
                            "/images/music/5_swiper/1_image/3000.jpg",
                            "/images/music/5_swiper/1_image/4000.jpg",
                            "/images/music/5_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/2_image/400.jpg",
                            "/images/music/5_swiper/2_image/600.jpg",
                            "/images/music/5_swiper/2_image/800.jpg",
                            "/images/music/5_swiper/2_image/1000.jpg",
                            "/images/music/5_swiper/2_image/1500.jpg",
                            "/images/music/5_swiper/2_image/2000.jpg",
                            "/images/music/5_swiper/2_image/3000.jpg",
                            "/images/music/5_swiper/2_image/4000.jpg",
                            "/images/music/5_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/3_image/400.jpg",
                            "/images/music/5_swiper/3_image/600.jpg",
                            "/images/music/5_swiper/3_image/800.jpg",
                            "/images/music/5_swiper/3_image/1000.jpg",
                            "/images/music/5_swiper/3_image/1500.jpg",
                            "/images/music/5_swiper/3_image/2000.jpg",
                            "/images/music/5_swiper/3_image/3000.jpg",
                            "/images/music/5_swiper/3_image/4000.jpg",
                            "/images/music/5_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/4_image/400.jpg",
                            "/images/music/5_swiper/4_image/600.jpg",
                            "/images/music/5_swiper/4_image/800.jpg",
                            "/images/music/5_swiper/4_image/1000.jpg",
                            "/images/music/5_swiper/4_image/1500.jpg",
                            "/images/music/5_swiper/4_image/2000.jpg",
                            "/images/music/5_swiper/4_image/3000.jpg",
                            "/images/music/5_swiper/4_image/4000.jpg",
                            "/images/music/5_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/5_image/400.jpg",
                            "/images/music/5_swiper/5_image/600.jpg",
                            "/images/music/5_swiper/5_image/800.jpg",
                            "/images/music/5_swiper/5_image/1000.jpg",
                            "/images/music/5_swiper/5_image/1500.jpg",
                            "/images/music/5_swiper/5_image/2000.jpg",
                            "/images/music/5_swiper/5_image/3000.jpg",
                            "/images/music/5_swiper/5_image/4000.jpg",
                            "/images/music/5_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/6_image/400.jpg",
                            "/images/music/5_swiper/6_image/600.jpg",
                            "/images/music/5_swiper/6_image/800.jpg",
                            "/images/music/5_swiper/6_image/1000.jpg",
                            "/images/music/5_swiper/6_image/1500.jpg",
                            "/images/music/5_swiper/6_image/2000.jpg",
                            "/images/music/5_swiper/6_image/3000.jpg",
                            "/images/music/5_swiper/6_image/4000.jpg",
                            "/images/music/5_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/7_image/400.jpg",
                            "/images/music/5_swiper/7_image/600.jpg",
                            "/images/music/5_swiper/7_image/800.jpg",
                            "/images/music/5_swiper/7_image/1000.jpg",
                            "/images/music/5_swiper/7_image/1500.jpg",
                            "/images/music/5_swiper/7_image/2000.jpg",
                            "/images/music/5_swiper/7_image/3000.jpg",
                            "/images/music/5_swiper/7_image/4000.jpg",
                            "/images/music/5_swiper/7_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/8_image/400.jpg",
                            "/images/music/5_swiper/8_image/600.jpg",
                            "/images/music/5_swiper/8_image/800.jpg",
                            "/images/music/5_swiper/8_image/1000.jpg",
                            "/images/music/5_swiper/8_image/1500.jpg",
                            "/images/music/5_swiper/8_image/2000.jpg",
                            "/images/music/5_swiper/8_image/3000.jpg",
                            "/images/music/5_swiper/8_image/4000.jpg",
                            "/images/music/5_swiper/8_image/5000.jpg"
                        ],
                        [
                            "/images/music/5_swiper/9_image/400.jpg",
                            "/images/music/5_swiper/9_image/600.jpg",
                            "/images/music/5_swiper/9_image/800.jpg",
                            "/images/music/5_swiper/9_image/1000.jpg",
                            "/images/music/5_swiper/9_image/1500.jpg",
                            "/images/music/5_swiper/9_image/2000.jpg",
                            "/images/music/5_swiper/9_image/3000.jpg",
                            "/images/music/5_swiper/9_image/4000.jpg",
                            "/images/music/5_swiper/9_image/5000.jpg"
                        ],
                    ]
            },
        ],
}