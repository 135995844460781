import { useFrame } from "@react-three/fiber"
import { useState } from "react"
import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import * as THREE from 'three';


export default function Physics_2({
    position = [0, 0, 0],
    scale,
    textures,
    geometries,
    materials
}) {

    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0}
        >

            {/* Floor */}
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ -scale[0] * 0.25, 0, 0 ]}
                scale={[scale[0] * 0.5, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                position={[ scale[0] * 0.375, 0, 0 ]}
                scale={[scale[0] * 0.25, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>

        </RigidBody>

        {/* Physic Block */}
        <RigidBody
            mass={0.1}
            restitution={0.9}
            friction={0.1}
            position={[-scale[0] * 0.5 + 3, 3, 0]}
        >
            <mesh
                // key={`${xIndex}-${yIndex}-${zIndex}`}
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={[2, 6, 1]}
                receiveShadow
                castShadow
            />
        </RigidBody>

        {/* Blendings */}
        <mesh
            geometry={geometries.planeGeometry}
            scale={[4.5, 13.5, 0]}
            position={[scale[0] * 0.135, 2, 4]}
            rotation-z={Math.PI}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.physics_2}
            />
        </mesh>
        {/* <mesh
            geometry={geometries.planeGeometry}
            scale={[15, 10.5, 0]}
            position={[scale[0] * 0.125, 1.8, -1]}
            // rotation-z={Math.PI}
        >
            <meshStandardMaterial
                depthWrite={false}
                blending={THREE.CustomBlending}
                blendEquation={THREE.MaxEquation}
                blendSrc={THREE.SrcAlphaFactor}
                blendDst={THREE.OneMinusSrcAlphaFactor}
                metalness={1.52}
                map={textures.physics_3}
            />
        </mesh> */}

    </group>
}

