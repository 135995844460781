import '../style/navigation.css';
import '../style/sizes.css';
import Isometries from './Isometries';
import { useState } from 'react';
import useGame from '../gameSrc/stores/useGame.jsx'
import useGlobal from '../stores/useGlobal.jsx';

export default function Navigation({ data, background = "black" }) 
{
    const phase = useGame(state => state.phase)

    return <div className="navigation">

        <Bar 
            data={ data.main } 
        />

        { (phase !== "playing" && phase !== "breathing") &&
            <>
                <Information data={data.information} />
                <Isometries 
                    className="isometries"
                    main={data.main}
                />
            </>
        }

    </div>
}


function Bar({ data, handleToggleIsometries }) 
{
    const dynamicTitle = useGame(state => state.dynamicTitle)
    const setIsometriesOpen = useGlobal(state => state.setIsometriesOpen)
    const isometriesOpen = useGlobal(state => state.isometriesOpen)

    const handleClick = () => {
        setIsometriesOpen(isometriesOpen ? false : true)
        console.log('click');
    };

    const handleLink = () => {
        // Perform any additional logic here if needed
        window.location.href = "/aboutMe";
    };

    return <div className="navigation__bar" onClick={handleClick}>

        <div className="bar__titles">
            <div className="titles__dynamicTitle">{ dynamicTitle }</div>
            <div className="titles__title">{ data.title }</div>
            <div className="titles__category" dangerouslySetInnerHTML={{ __html: data.category }} />
        </div>
        <div onClick={ handleLink } className="bar__download" style={{ cursor: 'pointer' }}>
            <span className="download__marco">Marco Spörri</span>
        </div>

        <div className="bar__menuBtn">
            <svg width="100" height="100" className='menuBtn_svg'>
                <line x1="0" y1="4" x2="30" y2="4" stroke="#00ffa0" strokeWidth="2" />
                <line x1="0" y1="10" x2="30" y2="10" stroke="#00ffa0" strokeWidth="2" />
                <line x1="0" y1="16" x2="30" y2="16" stroke="#00ffa0" strokeWidth="2" />
            </svg>
        </div>


    </div>
}


function Information({ data }) 
{
    return (
        <div className="navigation__information">
            {data.map((item, index) => 
            (
                <div className="information__item" key={index}>
                    <div className="item__title" dangerouslySetInnerHTML={{ __html: item.title }} />
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
            ))}
        </div>
    );
}