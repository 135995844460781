export const london =
{
    main:
    {
        title: "A Londonbus Simulation",
        category: "Editorial",
        page: "/london"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙220 &times; 280" },
            { title: "year", content: "∙2023" },
            { title: "weight", content: "∙542 g" },
            { title: "printing", content: "∙by Typotron <br> ∙digital" },
            { title: "binding", content: "∙by BUBU <br> ∙chainstitch" },
            { title: "Edition", content: "∙80 copies" },
        ],

    text:
    {
        adjectives:
            [
                { de: "Dramaturgie", en: "dramaturgy" },
                { de: "subtil", en: "subtle " },
                { de: "klassisch", en: "musical" },
                { de: "konzeptionell", en: "conceptual " },
                { de: " Matrix", en: "the Matrix" },
            ],

        content:
        {
            de: `Meine Publikation für das London Projekt hat die Struktur einer Fahrt mit einem London Bus. Mithilfe des Buches fährt man sieben virtuelle Stationen ab, von welchen jede ein anderes Thema verfolgt. Im siebten Kapitel passiert ein Unfall und der Rhythmus des Buches wird unterbrochen. Ein Fehler in der Simulation. Der Aufbau und die Idee des Buches sind für den Betrachter ein Rätsel, wobei er&#8198;/&#8198;sie die Idee des Konzeptes herausfinden soll. Mir war es aber ebenfalls wichtig, dass man die Werke der Gestalterinnen ganz unbefangen auch als solches geniessen kann und dass ich ihnen den angebrachten Platz gebe.` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/london/0_ansichten/400.jpg",
                        "/images/london/0_ansichten/600.jpg",
                        "/images/london/0_ansichten/800.jpg",
                        "/images/london/0_ansichten/1000.jpg",
                        "/images/london/0_ansichten/1500.jpg",
                        "/images/london/0_ansichten/2000.jpg",
                        "/images/london/0_ansichten/3000.jpg",
                        "/images/london/0_ansichten/4000.jpg",
                        "/images/london/0_ansichten/5000.jpg"
                    ]
            },

            // single 1
            {
                type: "single3D",
                srcset:
                    [
                        "/images/london/1_single/400.jpg",
                        "/images/london/1_single/600.jpg",
                        "/images/london/1_single/800.jpg",
                        "/images/london/1_single/1000.jpg",
                        "/images/london/1_single/1500.jpg",
                        "/images/london/1_single/2000.jpg",
                        "/images/london/1_single/3000.jpg",
                        "/images/london/1_single/4000.jpg",
                        "/images/london/1_single/5000.jpg"
                    ],
            },

            // swiper 2
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/london/2_swiper/1_image/400.jpg",
                            "/images/london/2_swiper/1_image/600.jpg",
                            "/images/london/2_swiper/1_image/800.jpg",
                            "/images/london/2_swiper/1_image/1000.jpg",
                            "/images/london/2_swiper/1_image/1500.jpg",
                            "/images/london/2_swiper/1_image/2000.jpg",
                            "/images/london/2_swiper/1_image/3000.jpg",
                            "/images/london/2_swiper/1_image/4000.jpg",
                            "/images/london/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/london/2_swiper/2_image/400.jpg",
                            "/images/london/2_swiper/2_image/600.jpg",
                            "/images/london/2_swiper/2_image/800.jpg",
                            "/images/london/2_swiper/2_image/1000.jpg",
                            "/images/london/2_swiper/2_image/1500.jpg",
                            "/images/london/2_swiper/2_image/2000.jpg",
                            "/images/london/2_swiper/2_image/3000.jpg",
                            "/images/london/2_swiper/2_image/4000.jpg",
                            "/images/london/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/london/2_swiper/3_image/400.jpg",
                            "/images/london/2_swiper/3_image/600.jpg",
                            "/images/london/2_swiper/3_image/800.jpg",
                            "/images/london/2_swiper/3_image/1000.jpg",
                            "/images/london/2_swiper/3_image/1500.jpg",
                            "/images/london/2_swiper/3_image/2000.jpg",
                            "/images/london/2_swiper/3_image/3000.jpg",
                            "/images/london/2_swiper/3_image/4000.jpg",
                            "/images/london/2_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/london/2_swiper/4_image/400.jpg",
                            "/images/london/2_swiper/4_image/600.jpg",
                            "/images/london/2_swiper/4_image/800.jpg",
                            "/images/london/2_swiper/4_image/1000.jpg",
                            "/images/london/2_swiper/4_image/1500.jpg",
                            "/images/london/2_swiper/4_image/2000.jpg",
                            "/images/london/2_swiper/4_image/3000.jpg",
                            "/images/london/2_swiper/4_image/4000.jpg",
                            "/images/london/2_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/london/2_swiper/5_image/400.jpg",
                            "/images/london/2_swiper/5_image/600.jpg",
                            "/images/london/2_swiper/5_image/800.jpg",
                            "/images/london/2_swiper/5_image/1000.jpg",
                            "/images/london/2_swiper/5_image/1500.jpg",
                            "/images/london/2_swiper/5_image/2000.jpg",
                            "/images/london/2_swiper/5_image/3000.jpg",
                            "/images/london/2_swiper/5_image/4000.jpg",
                            "/images/london/2_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/london/2_swiper/6_image/400.jpg",
                            "/images/london/2_swiper/6_image/600.jpg",
                            "/images/london/2_swiper/6_image/800.jpg",
                            "/images/london/2_swiper/6_image/1000.jpg",
                            "/images/london/2_swiper/6_image/1500.jpg",
                            "/images/london/2_swiper/6_image/2000.jpg",
                            "/images/london/2_swiper/6_image/3000.jpg",
                            "/images/london/2_swiper/6_image/4000.jpg",
                            "/images/london/2_swiper/6_image/5000.jpg"
                        ],
                    ]
            },

            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/london/3_single/400.jpg",
                        "/images/london/3_single/600.jpg",
                        "/images/london/3_single/800.jpg",
                        "/images/london/3_single/1000.jpg",
                        "/images/london/3_single/1500.jpg",
                        "/images/london/3_single/2000.jpg",
                        "/images/london/3_single/3000.jpg",
                        "/images/london/3_single/4000.jpg",
                        "/images/london/3_single/5000.jpg"
                    ],
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/london/4_swiper/1_image/400.jpg",
                            "/images/london/4_swiper/1_image/600.jpg",
                            "/images/london/4_swiper/1_image/800.jpg",
                            "/images/london/4_swiper/1_image/1000.jpg",
                            "/images/london/4_swiper/1_image/1500.jpg",
                            "/images/london/4_swiper/1_image/2000.jpg",
                            "/images/london/4_swiper/1_image/3000.jpg",
                            "/images/london/4_swiper/1_image/4000.jpg",
                            "/images/london/4_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/london/4_swiper/2_image/400.jpg",
                            "/images/london/4_swiper/2_image/600.jpg",
                            "/images/london/4_swiper/2_image/800.jpg",
                            "/images/london/4_swiper/2_image/1000.jpg",
                            "/images/london/4_swiper/2_image/1500.jpg",
                            "/images/london/4_swiper/2_image/2000.jpg",
                            "/images/london/4_swiper/2_image/3000.jpg",
                            "/images/london/4_swiper/2_image/4000.jpg",
                            "/images/london/4_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/london/4_swiper/3_image/400.jpg",
                            "/images/london/4_swiper/3_image/600.jpg",
                            "/images/london/4_swiper/3_image/800.jpg",
                            "/images/london/4_swiper/3_image/1000.jpg",
                            "/images/london/4_swiper/3_image/1500.jpg",
                            "/images/london/4_swiper/3_image/2000.jpg",
                            "/images/london/4_swiper/3_image/3000.jpg",
                            "/images/london/4_swiper/3_image/4000.jpg",
                            "/images/london/4_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/london/4_swiper/4_image/400.jpg",
                            "/images/london/4_swiper/4_image/600.jpg",
                            "/images/london/4_swiper/4_image/800.jpg",
                            "/images/london/4_swiper/4_image/1000.jpg",
                            "/images/london/4_swiper/4_image/1500.jpg",
                            "/images/london/4_swiper/4_image/2000.jpg",
                            "/images/london/4_swiper/4_image/3000.jpg",
                            "/images/london/4_swiper/4_image/4000.jpg",
                            "/images/london/4_swiper/4_image/5000.jpg"
                        ],
                    ]
            },

            // single 5
            {
                type: "single3D",
                srcset:
                    [
                        "/images/london/5_single/400.jpg",
                        "/images/london/5_single/600.jpg",
                        "/images/london/5_single/800.jpg",
                        "/images/london/5_single/1000.jpg",
                        "/images/london/5_single/1500.jpg",
                        "/images/london/5_single/2000.jpg",
                        "/images/london/5_single/3000.jpg",
                        "/images/london/5_single/4000.jpg",
                        "/images/london/5_single/5000.jpg"
                    ],
            },

            // swiper 6
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/london/6_swiper/1_image/400.jpg",
                            "/images/london/6_swiper/1_image/600.jpg",
                            "/images/london/6_swiper/1_image/800.jpg",
                            "/images/london/6_swiper/1_image/1000.jpg",
                            "/images/london/6_swiper/1_image/1500.jpg",
                            "/images/london/6_swiper/1_image/2000.jpg",
                            "/images/london/6_swiper/1_image/3000.jpg",
                            "/images/london/6_swiper/1_image/4000.jpg",
                            "/images/london/6_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/2_image/400.jpg",
                            "/images/london/6_swiper/2_image/600.jpg",
                            "/images/london/6_swiper/2_image/800.jpg",
                            "/images/london/6_swiper/2_image/1000.jpg",
                            "/images/london/6_swiper/2_image/1500.jpg",
                            "/images/london/6_swiper/2_image/2000.jpg",
                            "/images/london/6_swiper/2_image/3000.jpg",
                            "/images/london/6_swiper/2_image/4000.jpg",
                            "/images/london/6_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/3_image/400.jpg",
                            "/images/london/6_swiper/3_image/600.jpg",
                            "/images/london/6_swiper/3_image/800.jpg",
                            "/images/london/6_swiper/3_image/1000.jpg",
                            "/images/london/6_swiper/3_image/1500.jpg",
                            "/images/london/6_swiper/3_image/2000.jpg",
                            "/images/london/6_swiper/3_image/3000.jpg",
                            "/images/london/6_swiper/3_image/4000.jpg",
                            "/images/london/6_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/4_image/400.jpg",
                            "/images/london/6_swiper/4_image/600.jpg",
                            "/images/london/6_swiper/4_image/800.jpg",
                            "/images/london/6_swiper/4_image/1000.jpg",
                            "/images/london/6_swiper/4_image/1500.jpg",
                            "/images/london/6_swiper/4_image/2000.jpg",
                            "/images/london/6_swiper/4_image/3000.jpg",
                            "/images/london/6_swiper/4_image/4000.jpg",
                            "/images/london/6_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/5_image/400.jpg",
                            "/images/london/6_swiper/5_image/600.jpg",
                            "/images/london/6_swiper/5_image/800.jpg",
                            "/images/london/6_swiper/5_image/1000.jpg",
                            "/images/london/6_swiper/5_image/1500.jpg",
                            "/images/london/6_swiper/5_image/2000.jpg",
                            "/images/london/6_swiper/5_image/3000.jpg",
                            "/images/london/6_swiper/5_image/4000.jpg",
                            "/images/london/6_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/6_image/400.jpg",
                            "/images/london/6_swiper/6_image/600.jpg",
                            "/images/london/6_swiper/6_image/800.jpg",
                            "/images/london/6_swiper/6_image/1000.jpg",
                            "/images/london/6_swiper/6_image/1500.jpg",
                            "/images/london/6_swiper/6_image/2000.jpg",
                            "/images/london/6_swiper/6_image/3000.jpg",
                            "/images/london/6_swiper/6_image/4000.jpg",
                            "/images/london/6_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/london/6_swiper/6_image/400.jpg",
                            "/images/london/6_swiper/6_image/600.jpg",
                            "/images/london/6_swiper/6_image/800.jpg",
                            "/images/london/6_swiper/6_image/1000.jpg",
                            "/images/london/6_swiper/6_image/1500.jpg",
                            "/images/london/6_swiper/6_image/2000.jpg",
                            "/images/london/6_swiper/6_image/3000.jpg",
                            "/images/london/6_swiper/6_image/4000.jpg",
                            "/images/london/6_swiper/6_image/5000.jpg"
                        ],
                    ]
            },

            // single 7
            {
                type: "single3D",
                srcset:
                    [
                        "/images/london/7_single/400.jpg",
                        "/images/london/7_single/600.jpg",
                        "/images/london/7_single/800.jpg",
                        "/images/london/7_single/1000.jpg",
                        "/images/london/7_single/1500.jpg",
                        "/images/london/7_single/2000.jpg",
                        "/images/london/7_single/3000.jpg",
                        "/images/london/7_single/4000.jpg",
                        "/images/london/7_single/5000.jpg"
                    ],
            },

            // swiper 8
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/london/8_swiper/1_image/400.jpg",
                            "/images/london/8_swiper/1_image/600.jpg",
                            "/images/london/8_swiper/1_image/800.jpg",
                            "/images/london/8_swiper/1_image/1000.jpg",
                            "/images/london/8_swiper/1_image/1500.jpg",
                            "/images/london/8_swiper/1_image/2000.jpg",
                            "/images/london/8_swiper/1_image/3000.jpg",
                            "/images/london/8_swiper/1_image/4000.jpg",
                            "/images/london/8_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/london/8_swiper/2_image/400.jpg",
                            "/images/london/8_swiper/2_image/600.jpg",
                            "/images/london/8_swiper/2_image/800.jpg",
                            "/images/london/8_swiper/2_image/1000.jpg",
                            "/images/london/8_swiper/2_image/1500.jpg",
                            "/images/london/8_swiper/2_image/2000.jpg",
                            "/images/london/8_swiper/2_image/3000.jpg",
                            "/images/london/8_swiper/2_image/4000.jpg",
                            "/images/london/8_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/london/8_swiper/3_image/400.jpg",
                            "/images/london/8_swiper/3_image/600.jpg",
                            "/images/london/8_swiper/3_image/800.jpg",
                            "/images/london/8_swiper/3_image/1000.jpg",
                            "/images/london/8_swiper/3_image/1500.jpg",
                            "/images/london/8_swiper/3_image/2000.jpg",
                            "/images/london/8_swiper/3_image/3000.jpg",
                            "/images/london/8_swiper/3_image/4000.jpg",
                            "/images/london/8_swiper/3_image/5000.jpg"
                        ],
                    ]
            },
        ],
}