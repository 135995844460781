import React, { useRef, useState, useEffect } from 'react';
import "../style/welcome.css"
import { log } from 'three/examples/jsm/nodes/Nodes.js';

export default function Welcome() {
    const canvasRef = useRef(null);
    const welcomeRef = useRef(null)
    const [drawing, setDrawing] = useState(false);
    const [color, setColor] = useState('#00ffa0'); // Initial color is green

    useEffect(() => {
        // Show the Welcome component after a delay of 2.5 seconds
        const timeout = setTimeout(() => {
            welcomeRef.current.style.display = "flex";

            // Function to perform smooth scrolling down 100px
            if(innerWidth > 500) 
            {
                smoothScroll(200, 300, () => {
                    // After scrolling down, scroll back up after a delay of 1 second
                    setTimeout(() => {
                        smoothScroll(-200, 1000);
                    }, 0);
                });
            }
        }, 2500);

        // Clear the timeout to prevent memory leaks
        return () => clearTimeout(timeout);
    }, []);

    // Smooth scroll function using requestAnimationFrame with ease-out
    const smoothScroll = (distance, duration, callback) => {
        const start = window.scrollY;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const easeOutQuad = (time, from, distance, duration) => {
            time /= duration;
            return -distance * time * (time - 2) + from;
        };

        const animateScroll = (currentTime) => {
            const elapsed = currentTime - startTime;
            const nextScroll = easeOutQuad(elapsed, start, distance, duration);
            window.scrollTo(0, nextScroll);

            if (elapsed < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                if (callback) callback();  // Call the callback after the scroll finishes
            }
        };

        requestAnimationFrame(animateScroll);
    };

    // Function to start drawing
    const startDrawing = (e) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY;
        ctx.beginPath();
        ctx.moveTo(x, y);
        setDrawing(true);
    };

    // Function to draw
    const draw = (e) => {
        if (!drawing) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const x = e.nativeEvent.offsetX;
        const y = e.nativeEvent.offsetY;
        ctx.lineTo(x, y);
        ctx.strokeStyle = color;
        ctx.lineWidth = 5;
        ctx.stroke();
    };

    // Function to stop drawing
    const stopDrawing = () => {
        setDrawing(false);
    };

    // Function to save the drawing
    const saveDrawing = () => {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL();

        /* Hide Welcome */
        welcomeRef.current.style.display = "none"

        fetch('../php/save_drawing.php', {
            method: 'POST',
            body: JSON.stringify({ dataURL }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.error('Error saving drawing:', error);
            });
    };

    // close Welcome
    const closeWelcome = () => 
    {
        welcomeRef.current.style.display = "none"
    }

    // colors object
    const colors =
    {
        white: '#ffffff',
        violett: '#9191eb',
        red: '#ff0000',
        orange: '#ffc800',
        green: '#00ffa0',
        pink: '#ff00ff',
    }

    return (
        <div 
            className="welcome"
            ref={welcomeRef}
        >
            <div className='welcome__container'>

                <div className="container__text">
                    <p className="text__de">
                        Herzlich willkommen! <br></br>
                        Dies ist eine Sammlung meiner gra- <br /> fischen und künstlerischen Arbeit.
                        Zeichne etwas ↓
                    </p>
                    <p className="text__en">
                        A warm Welcome! <br></br>
                        This is a collection of my graphic <br /> and artistic work.<br></br>
                        Draw something ↓
                    </p>
                </div>

                <button 
                    className="container__close"
                    onClick={() => closeWelcome()}
                >
                    &times;
                </button>

                <canvas
                    className='container__canvas'
                    ref={canvasRef}
                    width={800}
                    height={400}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseOut={stopDrawing}
                />

                <div className="container__controls">

                    <div className="controls__colors">
                        {Object.keys(colors).map((colorKey) => (
                            <button
                                key={colorKey}
                                className=
                                {`
                                    colors__color 
                                    colors__${colorKey} 
                                    ${color === colors[colorKey] ? 'colors__color--selected' : ''}
                                `}
                                style={{ backgroundColor: colors[colorKey] }}
                                onClick={() => setColor(colors[colorKey])}
                            ></button>
                        ))}
                    </div>


                    <button
                        className='controls__button'
                        onClick={saveDrawing}
                    >
                        Upload Drawing
                    </button>

                </div>

            </div>
        </div>
    );
};
