export const megiZumsteinEditorial =
{
    main:
    {
        title: "Editorial bei Megi Zumstein",
        category: "Poster",
        page: "/megiZumsteinEditorial"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "Internship", content: "∙Megi Zumstein <br> &nbsp;visuelle <br> &nbsp;Gestaltung" },
            { title: "proportions", content: "∙170 &times; 230" },
            { title: "year", content: "∙2023" },
            { title: "printing", content: "∙offset <br> ∙600 copies" },
            { title: "customer", content: "∙Corinne <br> &nbsp;Odermatt" },
        ],

    text:
    {
        adjectives:
            [
                { de: "Zugehörigkeit", en: "Belonging" },
                { de: "Suche", en: "Search" },
                { de: "Textilien", en: "Textiles" },
                { de: "Daten", en: "Data" },
                { de: "Flattersatz", en: "Flutter set" },
            ],

        content:
        {
            de: `
            Die Künstlerin Corinne Odermatt gewann das Werjahr der Frey-Näpflin Stiftung und wir wurden beauftragt, aus ihrem Werk eine Publikation zu gestalten. Der erste Abschnitt zeigt eine Reihe an imaginären Ländern, in welchen sich Corinne mit dem Thema Zugehörigkeit und Heimat auseinandersetzt. Der zweite Abschnitt besteht aus einer Dramaturgischen Bildstrecke, welche wir aus ihrer Kunst, ihrem umfassenden Bildfundus und ihren Reisesdokumentationen auf über 300 Seiten erzählen.` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/megiZumsteinEditorial/0_ansichten/400.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/600.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/800.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/1000.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/1500.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/2000.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/3000.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/4000.jpg",
                        "/images/megiZumsteinEditorial/0_ansichten/5000.jpg"
                    ]
            },

            // videos 3
            {
                type: "videoBig",
                source: "/images/megiZumsteinEditorial/5_video/video.mp4"
            },


            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/megiZumsteinEditorial/3_single/400.jpg",
                        "/images/megiZumsteinEditorial/3_single/600.jpg",
                        "/images/megiZumsteinEditorial/3_single/800.jpg",
                        "/images/megiZumsteinEditorial/3_single/1000.jpg",
                        "/images/megiZumsteinEditorial/3_single/1500.jpg",
                        "/images/megiZumsteinEditorial/3_single/2000.jpg",
                        "/images/megiZumsteinEditorial/3_single/3000.jpg",
                        "/images/megiZumsteinEditorial/3_single/4000.jpg",
                        "/images/megiZumsteinEditorial/3_single/5000.jpg"
                    ],
                background: "rgb(145, 111, 195)"
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/megiZumsteinEditorial/1_swiper/1_image/400.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/600.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/800.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/1000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/1500.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/2000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/3000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/4000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinEditorial/1_swiper/2_image/400.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/600.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/800.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/1000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/1500.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/2000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/3000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/4000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinEditorial/1_swiper/3_image/400.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/600.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/800.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/1000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/1500.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/2000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/3000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/4000.jpg",
                            "/images/megiZumsteinEditorial/1_swiper/3_image/5000.jpg"
                        ],
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/megiZumsteinEditorial/2_swiper/1_image/400.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/600.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/800.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/1000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/1500.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/2000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/3000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/4000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinEditorial/2_swiper/2_image/400.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/600.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/800.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/1000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/1500.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/2000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/3000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/4000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinEditorial/2_swiper/3_image/400.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/600.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/800.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/1000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/1500.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/2000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/3000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/4000.jpg",
                            "/images/megiZumsteinEditorial/2_swiper/3_image/5000.jpg"
                        ],
                    ]
            },

            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/megiZumsteinEditorial/6_single/400.jpg",
                        "/images/megiZumsteinEditorial/6_single/600.jpg",
                        "/images/megiZumsteinEditorial/6_single/800.jpg",
                        "/images/megiZumsteinEditorial/6_single/1000.jpg",
                        "/images/megiZumsteinEditorial/6_single/1500.jpg",
                        "/images/megiZumsteinEditorial/6_single/2000.jpg",
                        "/images/megiZumsteinEditorial/6_single/3000.jpg",
                        "/images/megiZumsteinEditorial/6_single/4000.jpg",
                        "/images/megiZumsteinEditorial/6_single/5000.jpg"
                    ],
                background: "rgb(145, 111, 195)"
            },
        ],
}