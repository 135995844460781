export const lausanneProject =
{
    main:
    {
        title: "Lausanne Project",
        category: "Editorial",
        page: "/lausanneProject"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙45 &times; 85" },
            { title: "year", content: "∙2022" },
            { title: "weight", content: "∙180 g" },
            { title: "printing", content: "∙Plotter" },
            { title: "binding", content: "∙no binding" },
        ],

    text: 
    {
        adjectives: 
        [
            { de: "geometrisch", en: "geometric" },
            { de: "einheitlich", en: "uniform" },
            { de: "dystopisch", en: "dystopian" },
            { de: "linear", en: "linear" },
            { de: "kubisch", en: "cubic" },
        ],

        content: 
        {
            de: "Diese Publikation erzählt auf visueller Ebene von einer dystopischen Zukunft, in welcher die Menschen wie Ware behandelt werden. Während unserem 12-tägigen Aufenthalt in Lausanne habe ich mich mit einem der Gebiete beschäftigt, die kurz vor ihrem Umbau stehen. Es heisst ‹la Rasude›. Es soll ein lebendiger Ort entstehen, in welchem man arbeiten und zugleich leben kann. Gegen diese Pläne habe ich meine dystopischen Fantasien gesetzt. Auf 32 Seiten geht man auf eine Reise von der Konstruktion bis zum Zerfall utopischer Fiktionen. Alles ist auf nüchterne Weise dargestellt, um eine Manipulation des Betrachters zu verhindern.",

            en: "Diese Publikation erzählt auf visueller Ebene von einer dystopischen Zukunft, in welcher die Menschen wie Ware behandelt werden. Während unserem 12-tägigen Aufenthalt in Lausanne habe ich mich mit einem der Gebiete beschäftigt, die kurz vor ihrem Umbau stehen. Es heisst «la Rasude». Es soll ein lebendiger Ort entstehen, in welchem man arbeiten und zugleich leben kann. Gegen diese Pläne habe ich meine dystopischen Fantasien gesetzt. Auf 32 Seiten geht man auf eine Reise von der Konstruktion bis zum Zerfall utopischer Fiktionen. Alles ist auf nüchterne Weise dargestellt, um eine Manipulation des Betrachters zu verhindern."
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/lausanneProject/0_ansichten/400.jpg",
                        "/images/lausanneProject/0_ansichten/600.jpg",
                        "/images/lausanneProject/0_ansichten/800.jpg",
                        "/images/lausanneProject/0_ansichten/1000.jpg",
                        "/images/lausanneProject/0_ansichten/1500.jpg",
                        "/images/lausanneProject/0_ansichten/2000.jpg",
                        "/images/lausanneProject/0_ansichten/3000.jpg",
                        "/images/lausanneProject/0_ansichten/4000.jpg",
                        "/images/lausanneProject/0_ansichten/5000.jpg"
                    ]
            },

            // single 6
            {
                type: "single3D",
                srcset:
                    [
                        "/images/lausanneProject/6_single/400.jpg",
                        "/images/lausanneProject/6_single/600.jpg",
                        "/images/lausanneProject/6_single/800.jpg",
                        "/images/lausanneProject/6_single/1000.jpg",
                        "/images/lausanneProject/6_single/1500.jpg",
                        "/images/lausanneProject/6_single/2000.jpg",
                        "/images/lausanneProject/6_single/3000.jpg",
                        "/images/lausanneProject/6_single/4000.jpg",
                        "/images/lausanneProject/6_single/5000.jpg"
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset: 
                [
                    [
                        "/images/lausanneProject/1_swiper/image_1/1.1-400.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-600.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-800.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-1000.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-1500.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-2000.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-3000.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-4000.jpg",
                        "/images/lausanneProject/1_swiper/image_1/1.1-5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/1_swiper/image_2/1.2-400.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-600.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-800.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-1000.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-1500.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-2000.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-3000.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-4000.jpg",
                        "/images/lausanneProject/1_swiper/image_2/1.2-5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/1_swiper/image_3/1.3-400.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-600.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-800.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-1000.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-1500.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-2000.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-3000.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-4000.jpg",
                        "/images/lausanneProject/1_swiper/image_3/1.3-5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/1_swiper/image_4/1.4-400.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-600.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-800.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-1000.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-1500.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-2000.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-3000.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-4000.jpg",
                        "/images/lausanneProject/1_swiper/image_4/1.4-5000.jpg"
                    ],
                ]
            },
            
            // swiper 2
            {
                type: "swiper",
                srcset: 
                [
                    [
                        "/images/lausanneProject/2_swiper/image_1/400.jpg",
                        "/images/lausanneProject/2_swiper/image_1/600.jpg",
                        "/images/lausanneProject/2_swiper/image_1/800.jpg",
                        "/images/lausanneProject/2_swiper/image_1/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_1/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_1/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_1/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_1/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_1/5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/2_swiper/image_2/400.jpg",
                        "/images/lausanneProject/2_swiper/image_2/600.jpg",
                        "/images/lausanneProject/2_swiper/image_2/800.jpg",
                        "/images/lausanneProject/2_swiper/image_2/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_2/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_2/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_2/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_2/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_2/5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/2_swiper/image_3/400.jpg",
                        "/images/lausanneProject/2_swiper/image_3/600.jpg",
                        "/images/lausanneProject/2_swiper/image_3/800.jpg",
                        "/images/lausanneProject/2_swiper/image_3/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_3/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_3/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_3/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_3/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_3/5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/2_swiper/image_4/400.jpg",
                        "/images/lausanneProject/2_swiper/image_4/600.jpg",
                        "/images/lausanneProject/2_swiper/image_4/800.jpg",
                        "/images/lausanneProject/2_swiper/image_4/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_4/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_4/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_4/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_4/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_4/5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/2_swiper/image_5/400.jpg",
                        "/images/lausanneProject/2_swiper/image_5/600.jpg",
                        "/images/lausanneProject/2_swiper/image_5/800.jpg",
                        "/images/lausanneProject/2_swiper/image_5/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_5/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_5/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_5/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_5/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_5/5000.jpg"
                    ],
                    [
                        "/images/lausanneProject/2_swiper/image_6/400.jpg",
                        "/images/lausanneProject/2_swiper/image_6/600.jpg",
                        "/images/lausanneProject/2_swiper/image_6/800.jpg",
                        "/images/lausanneProject/2_swiper/image_6/1000.jpg",
                        "/images/lausanneProject/2_swiper/image_6/1500.jpg",
                        "/images/lausanneProject/2_swiper/image_6/2000.jpg",
                        "/images/lausanneProject/2_swiper/image_6/3000.jpg",
                        "/images/lausanneProject/2_swiper/image_6/4000.jpg",
                        "/images/lausanneProject/2_swiper/image_6/5000.jpg"
                    ],
                ]
            },


            // single 7
            {
                type: "single3D",
                srcset:
                    [
                        "/images/lausanneProject/7_single/400.jpg",
                        "/images/lausanneProject/7_single/600.jpg",
                        "/images/lausanneProject/7_single/800.jpg",
                        "/images/lausanneProject/7_single/1000.jpg",
                        "/images/lausanneProject/7_single/1500.jpg",
                        "/images/lausanneProject/7_single/2000.jpg",
                        "/images/lausanneProject/7_single/3000.jpg",
                        "/images/lausanneProject/7_single/4000.jpg",
                        "/images/lausanneProject/7_single/5000.jpg"
                    ]
            },

            // single 3
            {
                type: "single2D",
                srcset: 
                [
                    "/images/lausanneProject/3_single/400.jpg",
                    "/images/lausanneProject/3_single/600.jpg",
                    "/images/lausanneProject/3_single/800.jpg",
                    "/images/lausanneProject/3_single/1000.jpg",
                    "/images/lausanneProject/3_single/1500.jpg",
                    "/images/lausanneProject/3_single/2000.jpg",
                    "/images/lausanneProject/3_single/3000.jpg",
                    "/images/lausanneProject/3_single/4000.jpg",
                    "/images/lausanneProject/3_single/5000.jpg"
                ]
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/lausanneProject/4_swiper/1_image/400.jpg",
                            "/images/lausanneProject/4_swiper/1_image/600.jpg",
                            "/images/lausanneProject/4_swiper/1_image/800.jpg",
                            "/images/lausanneProject/4_swiper/1_image/1000.jpg",
                            "/images/lausanneProject/4_swiper/1_image/1500.jpg",
                            "/images/lausanneProject/4_swiper/1_image/2000.jpg",
                            "/images/lausanneProject/4_swiper/1_image/3000.jpg",
                            "/images/lausanneProject/4_swiper/1_image/4000.jpg",
                            "/images/lausanneProject/4_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/lausanneProject/4_swiper/2_image/400.jpg",
                            "/images/lausanneProject/4_swiper/2_image/600.jpg",
                            "/images/lausanneProject/4_swiper/2_image/800.jpg",
                            "/images/lausanneProject/4_swiper/2_image/1000.jpg",
                            "/images/lausanneProject/4_swiper/2_image/1500.jpg",
                            "/images/lausanneProject/4_swiper/2_image/2000.jpg",
                            "/images/lausanneProject/4_swiper/2_image/3000.jpg",
                            "/images/lausanneProject/4_swiper/2_image/4000.jpg",
                            "/images/lausanneProject/4_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/lausanneProject/4_swiper/3_image/400.jpg",
                            "/images/lausanneProject/4_swiper/3_image/600.jpg",
                            "/images/lausanneProject/4_swiper/3_image/800.jpg",
                            "/images/lausanneProject/4_swiper/3_image/1000.jpg",
                            "/images/lausanneProject/4_swiper/3_image/1500.jpg",
                            "/images/lausanneProject/4_swiper/3_image/2000.jpg",
                            "/images/lausanneProject/4_swiper/3_image/3000.jpg",
                            "/images/lausanneProject/4_swiper/3_image/4000.jpg",
                            "/images/lausanneProject/4_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/lausanneProject/4_swiper/4_image/400.jpg",
                            "/images/lausanneProject/4_swiper/4_image/600.jpg",
                            "/images/lausanneProject/4_swiper/4_image/800.jpg",
                            "/images/lausanneProject/4_swiper/4_image/1000.jpg",
                            "/images/lausanneProject/4_swiper/4_image/1500.jpg",
                            "/images/lausanneProject/4_swiper/4_image/2000.jpg",
                            "/images/lausanneProject/4_swiper/4_image/3000.jpg",
                            "/images/lausanneProject/4_swiper/4_image/4000.jpg",
                            "/images/lausanneProject/4_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/lausanneProject/4_swiper/5_image/400.jpg",
                            "/images/lausanneProject/4_swiper/5_image/600.jpg",
                            "/images/lausanneProject/4_swiper/5_image/800.jpg",
                            "/images/lausanneProject/4_swiper/5_image/1000.jpg",
                            "/images/lausanneProject/4_swiper/5_image/1500.jpg",
                            "/images/lausanneProject/4_swiper/5_image/2000.jpg",
                            "/images/lausanneProject/4_swiper/5_image/3000.jpg",
                            "/images/lausanneProject/4_swiper/5_image/4000.jpg",
                            "/images/lausanneProject/4_swiper/5_image/5000.jpg"
                        ],
                    ]
            },

            // single 5
            {
                type: "single3D",
                srcset:
                    [
                        "/images/lausanneProject/5_single/400.jpg",
                        "/images/lausanneProject/5_single/600.jpg",
                        "/images/lausanneProject/5_single/800.jpg",
                        "/images/lausanneProject/5_single/1000.jpg",
                        "/images/lausanneProject/5_single/1500.jpg",
                        "/images/lausanneProject/5_single/2000.jpg",
                        "/images/lausanneProject/5_single/3000.jpg",
                        "/images/lausanneProject/5_single/4000.jpg",
                        "/images/lausanneProject/5_single/5000.jpg"
                    ]
            },

        ],
}