export const korrespondenzkarten =
{
    main:
    {
        title: "FMZ Korrespondenzkarten",
        category: "Editorial",
        page: "/korrespondenzkarten"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙149 &times; 210" },
            { title: "year", content: "∙2022" },
            { title: "printing", content: "∙Hand Screen <br> ∙Offset" },
            { title: "edition", content: "∙600 copies" },
        ],

    text:
    {
        adjectives:
            [
                { de: "farbig", en: "colorful" },
                { de: "Papier", en: "Paper " },
                { de: "umblättern", en: "turn over" },
                { de: "Neuausrichtung", en: "Reorientation " },
                { de: "Wiederkunft", en: "Recurrence" },
            ],

        content:
        {
            de: `
            Für das Fach- und Wirtschaftsmittelschulzentrum Luzern gestaltete ich diese Entwürfe für Neujahrs- sowie Korrespondenzkarten. Bei jeder wird das Thema Umbruch, Neuausrichtung und Wiederkunft thematisiert.
            In der Berufskunde lernten wir alles um das Thema Druck und ‹Prepress› kennen und setzten unsere eigenen Vorschläge im Siebdruck um. Zwei meiner Karten wurden am Schluss von offizieller Seite ausgewählt und in einer kleinen Stückzahl umgesetzt. ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/korrespondenzkarten/0_ansichten/400.jpg",
                        "/images/korrespondenzkarten/0_ansichten/600.jpg",
                        "/images/korrespondenzkarten/0_ansichten/800.jpg",
                        "/images/korrespondenzkarten/0_ansichten/1000.jpg",
                        "/images/korrespondenzkarten/0_ansichten/1500.jpg",
                        "/images/korrespondenzkarten/0_ansichten/2000.jpg",
                        "/images/korrespondenzkarten/0_ansichten/3000.jpg",
                        "/images/korrespondenzkarten/0_ansichten/4000.jpg",
                        "/images/korrespondenzkarten/0_ansichten/5000.jpg"
                    ]
            },

            // single 1
            {
                type: "single3D",
                srcset:
                    [
                        "/images/korrespondenzkarten/1_single/400.jpg",
                        "/images/korrespondenzkarten/1_single/600.jpg",
                        "/images/korrespondenzkarten/1_single/800.jpg",
                        "/images/korrespondenzkarten/1_single/1000.jpg",
                        "/images/korrespondenzkarten/1_single/1500.jpg",
                        "/images/korrespondenzkarten/1_single/2000.jpg",
                        "/images/korrespondenzkarten/1_single/3000.jpg",
                        "/images/korrespondenzkarten/1_single/4000.jpg",
                        "/images/korrespondenzkarten/1_single/5000.jpg"
                    ],
                background: "white"
            },

            // swiper 2
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/korrespondenzkarten/2_swiper/1_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/2_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/3_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/4_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/5_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/6_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/korrespondenzkarten/2_swiper/7_image/400.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/600.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/800.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/1000.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/1500.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/2000.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/3000.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/4000.jpg",
                            "/images/korrespondenzkarten/2_swiper/7_image/5000.jpg"
                        ],
                    ]
            },

            // single 3
            {
                type: "single3D",
                srcset:
                    [
                        "/images/korrespondenzkarten/3_single/400.jpg",
                        "/images/korrespondenzkarten/3_single/600.jpg",
                        "/images/korrespondenzkarten/3_single/800.jpg",
                        "/images/korrespondenzkarten/3_single/1000.jpg",
                        "/images/korrespondenzkarten/3_single/1500.jpg",
                        "/images/korrespondenzkarten/3_single/2000.jpg",
                        "/images/korrespondenzkarten/3_single/3000.jpg",
                        "/images/korrespondenzkarten/3_single/4000.jpg",
                        "/images/korrespondenzkarten/3_single/5000.jpg"
                    ],
                background: "white"
            },
        ],
}