import React, { useState, useEffect, useRef, useCallback } from 'react';
import gsap from 'gsap';
import useGame from '../stores/useGame';
import { useGSAP } from '@gsap/react';

export default function WordAnimation() {
    const [inputValue, setInputValue] = useState('');
    const [prevWordIndex, setPrevWordIndex] = useState(null);
    const [waiting, setWaiting] = useState(true)

    //global States
    const setInputText = useGame(state => state.setInputText)
    const inputText = useGame(state => state.inputText)

    // Create a ref for the input element
    const inputRef = useRef(null);
    const speakState = useRef()

    // Focus on the input when the component mounts
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleInputChange = (event) => 
    {
        const newValue = event.target.value;

        setInputValue(newValue);
        setInputText(newValue)
        setWaiting(false)

        speakState.current.style.opacity = 0.5
        speakState.current.innerHTML = 'Sprechplanung'

        if (newValue.endsWith(' ')) {
            const prevSpaceIndex = newValue.lastIndexOf(' ', newValue.length - 2);
            const newPrevWordIndex = prevSpaceIndex + 1;

            if (newPrevWordIndex !== prevWordIndex) {
                animateWord(newPrevWordIndex);
                setPrevWordIndex(newPrevWordIndex);
            }
        }
    };


    const animateWord = (startIndex) => {
        const tl = gsap.timeline();
        const characters = [...inputValue];
        tl.clear();

        speakState.current.innerHTML = 'Sprechen'

        tl.fromTo(
            speakState.current,
            { opacity: 0.5, fontVariationSettings: "'wdth' 500" },
            { opacity: 1, fontVariationSettings: "'wdth' 100", duration: 1, ease: 'bounce' },
            0
        );

        for (let i = startIndex - 1; i < characters.length; i++) {
            tl.fromTo(
                `.char-${i}`,
                { opacity: 0.5, fontVariationSettings: "'wdth' 500" },
                { opacity: 1, fontVariationSettings: "'wdth' 100", duration: 1, ease: 'bounce' },
                0
            );
        }
    };


    return (
        <div className='startScreen'>

            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Type a word"
                ref={inputRef}
                style={{ color: 'white' }}
                className='startScreen__input'
            />

            <div className="startScreen__font">
                <div className="font__title"></div>
                <div className="font__placeholder"></div>
                <div className="font__state" ref={speakState}></div>
                <div className="animation-container font__display">
                    {inputValue.split('').map((char, index) => (
                        <span key={index} className={`char-${index}`} style={{ opacity: 0.5 }} >
                            {char}
                            {/* {console.log(char)} */}
                        </span>
                    ))}
                </div>

            </div>

            <VideoPlayer />
            {waiting && <StartScreenVideo />}
            {waiting && <WaitingAnimation />}

        </div>
    );
};


function WaitingAnimation() {
    const [waitingIndex, setWaitingIndex] = useState(0)
    const waitingLabel = useRef()

    useEffect(() => {
        const intervalId = setInterval(() => {
            setWaitingIndex((prevCount) => (prevCount + 1) % 3);
        }, 4000);

        return () => clearInterval(intervalId);
    },
        [])

    useGSAP(() => {
        gsap.to(
            `.waiting__${waitingIndex}`,
            {
                color: 'red',
                fontVariationSettings: "'wdth' 100",
                duration: 1,
                ease: 'bounce', // Set ease for the forward animation
                yoyo: true,
                repeat: 1,
                yoyoEase: 'power1.inOut', // Set a different ease for the yoyo animation
            }
        );

        if (waitingIndex === 0) { waitingLabel.current.innerHTML = 'Wortdehnungen' }
        if (waitingIndex === 1) { waitingLabel.current.innerHTML = 'Wortblockierungen' }
        if (waitingIndex === 2) { waitingLabel.current.innerHTML = 'Wortwiederholungen' }
    },
        [waitingIndex]);


    return <div className="startScreen__waiting">
        <div className="waiting__placeholder"></div>
        <div
            className="waiting__label"
            ref={waitingLabel}>Wortwiederholung
        </div>
        <div className="waiting__animation">
            <span className="waiting__0">1</span>
            <span className="waiting__1">2</span>
            <span className="waiting__2">3</span>
        </div>
        <div className="waiting__title">Press Enter <br /> to start Demo</div>
    </div>
}

function StartScreenVideo() {
    const [isInactive, setIsInactive] = useState(false);
    let inactivityTimeout;
    let restartTimeout;

    const startInactivityTimer = useCallback(() => {
        inactivityTimeout = setTimeout(() => {
            setIsInactive(true);
        }, 10000); // 10 seconds
    }, []);

    const resetInactivityTimer = useCallback(() => {
        clearTimeout(inactivityTimeout);
        clearTimeout(restartTimeout); // Clear any pending restart timeout
        setIsInactive(false);
        startInactivityTimer();
    }, [startInactivityTimer]);

    const handleVideoEnd = useCallback(() => {
        setIsInactive(false); // Hide the video
        restartTimeout = setTimeout(() => {
            setIsInactive(true); // Show the video after 10 seconds
        }, 10000); // 10 seconds
    }, []);

    useEffect(() => {
        startInactivityTimer();
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        return () => {
            clearTimeout(inactivityTimeout);
            clearTimeout(restartTimeout);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, [resetInactivityTimer, startInactivityTimer, handleVideoEnd]);

    return (
        <div className='startScreenVideo'>
            {isInactive && (
                <div className="startScreenVideo__container">
                    <video
                        autoPlay
                        muted
                        className='container__video'
                        onEnded={handleVideoEnd}
                    >
                        <source
                            src="/game/video/startScreenVideo.mov"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );
}




// Video Player
const VideoPlayer = () => {
    const [keySequence, setKeySequence] = useState([]);
    const [showVideo, setShowVideo] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const videoRef = useRef(null);

    const videoMappings = {
        'dune ': '/game/video/dune.mov',
        'the lord of the rings ': '/game/video/theLordOfTheRings.mov',
        'harry potter ': '/game/video/harryPotter.mov',
        'hans ': '/game/video/dune.mp4',
        'pirates of the caribbean ': '/game/video/pirates.mov'
    };

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleKeyDown = (event) => {
        const newKey = event.key.toLowerCase();
        const correctSequences = Object.keys(videoMappings);
        let updatedSequence = [...keySequence, newKey];

        const matchedSequence = correctSequences.find(sequence =>
            sequence.slice(0, updatedSequence.length) === updatedSequence.join('')
        );

        if (matchedSequence) {
            if (updatedSequence.join('') === matchedSequence) {
                setShowVideo(true);
                setVideoSrc(videoMappings[matchedSequence]);
                playVideo();
                updatedSequence = []; // Reset sequence after playing the video
            }
        } else {
            updatedSequence = []; // Reset sequence if the keys are not in the correct order
        }

        setKeySequence(updatedSequence);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keySequence]);

    return (
        <div>
            {showVideo && (
                <video
                    ref={videoRef}
                    width="600"
                    autoPlay
                    style={{
                        height: "100vh",
                        width: "100vw",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "100000"
                    }}
                >
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};
