import useSound from "use-sound";
import useGame from "../stores/useGame";
import { useEffect, useState } from "react";

// reusable Sounds
import itemCollectedBreathSound from '/game/sound/playing/breathSound-1.mp3'
import level_breathing from '/game/sound/level-breath/PA_Musik_level-breath.mp3'

// Music
import startScreen_background from '/game/sound/startScreen/background/PA-Music_StartScreen_background.mp3'

// effects
import effects_block from '/game/sound/level-effects/effect-block/PA_Musik_effects_block.mp3'
import effects_dehnung from '/game/sound/level-effects/effect-dehnung/PA_Musik_effects_dehung.mp3'

// Sound Effects
import soundEffects_die from '/game/sound/level-soundEffects/PA-Music_SoundEffects_die.mp3'

// Level 1
import level1_base from '/game/sound/level-1/PA_Musik_Level1_base.mp3'
import level1_full from '/game/sound/level-1/PA_Musik_Level1_full.mp3'

// Level 2
import level2_full from '/game/sound/level-2/PA_Musik_level2_full.mp3'

// Level 2
import level3_full from '/game/sound/level-3/PA_Musik_level-3_full.mp3'

export default function Sound() 
{
    // zustand
    const inputText = useGame(state => state.inputText)
    const itemBreathCount = useGame(state => state.itemBreathCount)
    const phase = useGame(state => state.phase)
    const gameLevel = useGame(state => state.gameLevel)
    const mainTextCurrentWord = useGame(state => state.mainTextCurrentWord)
    const mainTextPreviousWord = useGame(state => state.mainTextPreviousWord)
    const deathCount = useGame(state => state.deathCount)

    // Level Music
    const [level1_base_play, { stop: level1_base_stop, sound: level1_base_sound }] = useSound(level1_base, { loop: true })
    const [level1_full_play, { stop: level1_full_stop, sound: level1_full_sound }] = useSound(level1_full, { loop: true })
    const [level2_full_play, { stop: level2_full_stop, sound: level2_full_sound }] = useSound(level2_full, { loop: true })
    const [level3_full_play, { stop: level3_full_stop, sound: level3_full_sound }] = useSound(level3_full, { loop: true })

    // startScreen Music
    const [startScreen_play, { stop: startScreen_stop, sound: startScreen_sound }] = useSound(startScreen_background, { loop: true, volume: 0.5 })

    // Effects
    const [effects_block_play, { stop: effects_block_stop }] = useSound(effects_block)
    const [effects_dehung_play, { stop: effects_dehung_stop }] = useSound(effects_dehnung)

    const [level_breathing_play, { stop: level_breathing_stop }] = useSound(level_breathing)

    // SoundEffects
    const [itemBreathCollectedPlay] = useSound(itemCollectedBreathSound)
    const [soundEffect_die_play] = useSound(soundEffects_die)

    /* 
    ** Playing
    */
    useEffect(() => 
    {
        itemBreathCollectedPlay()
    },
    [itemBreathCount])



    /* Background Music */
    useEffect(() => 
    {
        /* Level Sound Playing */

        // Level 1
        if(gameLevel == 1) 
        {
            if (phase === 'playing') 
            {
                level1_base_play()
                level1_full_play()
                startScreen_stop()

                level1_base_sound.volume(1.0)
                level1_full_sound.volume(0.0)
            }
        }

        if(gameLevel == 2) 
        {
            if(phase === 'playing') 
            {
                level2_full_play()
            }
        }

        if (gameLevel == 3 || gameLevel == 4 || gameLevel == 5) 
        {
            if(phase === 'playing') 
            {
                level3_full_play()
            }
        }


        // breath Sounds
        if (phase === "breathing") 
        {
            // Stop Sounds
            level1_base_stop()
            level1_full_stop()
            level2_full_stop()
            level3_full_stop()

            level_breathing_play()
        }


        // stop Sounds
        if(phase === "waiting") 
        {
            level1_base_stop()
            level1_full_stop()
            level_breathing_stop()

            level2_full_stop()
            level3_full_stop()

            effects_dehung_stop()
            effects_block_stop()

            startScreen_play()
        }
    },
        [phase])


    
    /* Change Music Ligature */
    useEffect(() => 
    {
        // current Ligature
        if (mainTextCurrentWord && Object.keys(mainTextCurrentWord).length !== 0) 
        {
            let currentLigature = mainTextCurrentWord.firstChild.innerHTML.slice(6)

            console.log(currentLigature)

            if (currentLigature === "De") 
            {
                level1_full_sound.volume(0.0)
                level2_full_sound.volume(0.0)
                effects_block_play()
            }
            if (currentLigature === "mei") 
            {
                effects_dehung_play()
                level1_base_sound.volume(1.0)
            }
        }

        if (mainTextPreviousWord !== undefined && Object.keys(mainTextCurrentWord).length !== 0) 
        {
            let previousLigature = mainTextPreviousWord.firstChild.innerHTML.slice(6)

            if (previousLigature === "De") 
            {
                level1_full_sound.volume(1.0)
                level2_full_sound.volume(1.0)
                effects_block_stop()
            }

            if (previousLigature === "mei") 
            {
                effects_dehung_stop()
                level1_base_sound.volume(0.0)
            }
        }
    }, 
    [mainTextCurrentWord])



    /* Item Breath Count */
    useEffect(() => 
    {
        // when first breath collected
        if(itemBreathCount === 1) 
        {
            level1_base_sound.volume(0.0)
            level1_full_sound.volume(1.0)
        }
    }, 
    [itemBreathCount])

    /* Death Count */
    useEffect(() => 
    {
        soundEffect_die_play()
    },
        [deathCount])


    return null
}