export const aboutMe =
{
    main:
    {
        title: "about Me",
        category: " ",
        page: "/aboutMe",
    },

    isometrie: 
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            // { title: "2016&#8198;–&#8198;2019", content: "Sekundarstufe A <br> Dürnten" },
            { title: "2019&#8198;–&#8198;2020", content: "<a href='https://sfgz.ch/angebot/vorkurs/informationen'>Gestalterischer Vorkurs <br> Zürich</a>" },
            { title: "2020&#8198;–&#8198;2024", content: "<a href='https://fachklassegrafik.ch/'>Fachklasse Grafik <br> Luzern</a>" },
            { title: "2023", content: "<a href='https://megizumstein.ch/'>Praktikum bei <br> Megi Zumstein</a>" },
            { title: "2024", content: "<a href='https://bivgrafik.ch/'>Praktikum bei <br> Bivgrafik</a>" },
        ],
    text:
    {
        adjectives:
            [
                { de: "E-Mail", en: "marcospoerri@bluewin.ch" },
                { de: "number", en: "+41 76 819 86 35" },
                { de: "Instagram", en: "@marco___spoerri" },
            ],

        content:
        {
            de: `
            Hallo, mein Name ist Marco und ich habe diesen Sommer die Ausbildung an der Fachklasse Grafik abgeschlossen. An der Fachklasse lernte ich die verschiedenen Dimensionen des grafischen Handwerks kennen. Die Balance zwischen Selbstverwirklichung und Zweckerfüllung ist mir sehr wichtig mit dem Ziel Grafik nicht nur für mich, sondern auch für die Welt zu machen.

            Ich mag Kaffee mit Hafermilch. Ich mag es, Dinge von allen Seiten zu analysieren. Ich mag es Regeln zu erstellen und diese bewusst zu brechen. Ich mag es, Dinge auseinanderzunehmen, kritisch zu hinterfragen und wieder zusammenzusetzen. Ich mag es, den Dingen ein «Warum» zu geben. Ich mag es nicht, mich als etwas Besseres zu verkaufen als ich bin.` ,

            en: `Hello, my name is Marco, and I'm currently in the third year of study at «Fachklasse Grafik» based in Lucerne. During my education, I learn the different dimensions of the graphic craft. The balance of self-fulfillment and purpose fulfillment is very important to me with the Goal, to make graphic not only for me but for the world. 
            
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            I like coffee with oat milk. I like to analyze things from the front, from behind, from above, from below and from the sides. I like to develop rules and to break them deliberately. I like to deconstruct things, question and to recompose them. I like to give things a «why». I don't like it to sell myself as something better as I really am.` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/aboutMe/0_ansichten/400.jpg",
                        "/images/aboutMe/0_ansichten/600.jpg",
                        "/images/aboutMe/0_ansichten/800.jpg",
                        "/images/aboutMe/0_ansichten/1000.jpg",
                        "/images/aboutMe/0_ansichten/1500.jpg",
                        "/images/aboutMe/0_ansichten/2000.jpg",
                        "/images/aboutMe/0_ansichten/3000.jpg",
                        "/images/aboutMe/0_ansichten/4000.jpg",
                        "/images/aboutMe/0_ansichten/5000.jpg"
                    ]
            },
        ],
    
        skills: 
        {
            programms:
                [
                    { title: "ID", level: 100 },
                    { title: "AI", level: 90 },
                    { title: "PS", level: 90 },
                    { title: "AE", level: 70, developing: true },
                    { title: "XD", level: 50 },
                    { title: "LR", level: 70 },
                    { title: "Blender", level: 20, developing: true },
                ],

            languages:
                [
                    { title: "Html", level: 80 },
                    { title: "CSS", level: 70 },
                    { title: "JS", level: 70, developing: true },
                    { title: "React", level: 50, developing: true },
                    { title: "THREE", level: 50, developing: true },
                    { title: "R3F", level: 50, developing: true },
                    { title: "PHP", level: 10, developing: true },
                ]
        }
}