import { useFrame } from "@react-three/fiber"
import { useState } from "react"
import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import { useContext, useEffect } from "react";
import { Html } from "@react-three/drei";
import { KeyboardContext } from "../Helpers/KeyboardContext";
import useGame from "../stores/useGame";
import * as THREE from 'three';


export default function Attraction_1({ 
    position = [0, 0, 0],
    scale,
    textures,
    geometries,
    materials
}) 
{
    const { subscribeKeys, getKeys } = useContext(KeyboardContext)
    let [strength, setStrength] = useState(2)
    const playerPosition = useGame(state => state.playerPosition)


    /* Listen to Space Button */
    useEffect(() => {
        const unsubscribeJump = subscribeKeys(
            // Selector: "Listen to the jump key"
            (state) => state.jump,
            // When Jumped, then call function
            (pressed) => {
                if (pressed) {
                    if (playerPosition.x > position[0] - scale[0] * 0.5 && playerPosition.x < position[0] + scale[0] * 0.5) {
                        setStrength(strength >= 0 ? strength -= 0.1 : 0)
                    }
                }
            }
        )

        return () => {
            unsubscribeJump()
        }
    },
        [playerPosition])


    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0}
        >

        {/* Floor */}
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={scale}
                receiveShadow
            >
            </mesh>

        {/* Attactors */}
            <Attractor
                range={5}
                type='static'
                strength={strength}
                position={[0, 2, 0]}
            />

        </RigidBody>

        {strength > 0 &&
        <Blending
            textures={textures}
            planeGeometry={geometries.planeGeometry}
            strength={strength}
        />}

        {strength > 0 && 
        <Label
            position={position}
            scale={scale}
        />}

    </group>
}


function Blending({
    textures,
    planeGeometry,
    strength,
}) 
{
    return <mesh
        geometry={planeGeometry}
        scale={[strength * 5, strength * 5, 0]}
        position={[0, 3, 5]}
    >
        <meshStandardMaterial
            depthWrite={false}
            blending={THREE.CustomBlending}
            blendEquation={THREE.MaxEquation}
            blendSrc={THREE.SrcAlphaFactor}
            blendDst={THREE.OneMinusSrcAlphaFactor}
            metalness={1.52}
            map={textures.circle_1}
        />
    </mesh>
}


function Label({
    position,
    scale
}) 
{
    const [opacity, setOpacity] = useState(1)

    useFrame(({ clock }) => 
    {
        let time = clock.getElapsedTime()
        setOpacity(Math.sin(time * 10) + 2 * 0.25)
    })

    return <Html style={
        {  
            width: scale[0] * 60,
            transform: `translate(-${scale[0] * 60 * 0.5}px, -100px)`,
            opacity: opacity
        }}
        className="plattform__label"
        >

        Press Space <br/>
        to escape

    </Html>
}