import { useRapier, RigidBody } from '@react-three/rapier'
import { useFrame } from "@react-three/fiber"
import { useEffect, useRef, useState, useContext } from "react"
import { Edges } from '@react-three/drei'
import * as THREE from 'three'
import useSound from 'use-sound'

import { KeyboardContext } from './Helpers/KeyboardContext'
import useGame from './stores/useGame'

// reusable Sounds
import jumpSound from '/game/sound/level-soundEffects/PA-Music_SoundEffects_jump.mp3'

const playerGeometry = new THREE.BoxGeometry(1, 1, 1)
const playerMaterial = new THREE.MeshStandardMaterial({ color: 'red' })
const edgesMaterial = new THREE.MeshBasicMaterial({ color: 'black', toneMapped: false })

export default function Player() 
{
    const player = useRef()
    const { rapier, world } = useRapier()
    const [ smoothedCameraPosition ] = useState(() => new THREE.Vector3(0, 5, 0))
    const [ smoothedCameraTarget ] = useState(() => new THREE.Vector3())

    const { subscribeKeys, getKeys } = useContext(KeyboardContext)
    
    //Sound
    const [jumpSound_play] = useSound(jumpSound)
    
    // Zustand
    const restartGame = useGame(state => state.restartGame)
    const resetGameLevel = useGame(state => state.resetGameLevel)
    const setGameLevel = useGame(state => state.setGameLevel)
    const playerIsSlow = useGame(state => state.playerIsSlow)
    const setPlayerIsFast = useGame(state => state.setPlayerIsFast)
    const setPlayerIsSlow = useGame(state => state.setPlayerIsSlow)
    const setPlayerPosition = useGame(state => state.setPlayerPosition)
    const playerPosition = useGame(state => state.playerPosition)
    const startGame = useGame(state => state.startGame)
    const breathGame = useGame(state => state.breathGame)
    const levelDimensions = useGame(state => state.levelDimensions)
    const gameLevel = useGame(state => state.gameLevel)
    const phase = useGame(state => state.phase)
    const setItemBreathCount = useGame(state => state.setItemBreathCount)
    const deathCount = useGame(state => state.deathCount)
    const setDeathCount = useGame(state => state.setDeathCount)

    const wordDimensions = useGame(state => state.wordDimensions)
    const setMainTextCurrentWord = useGame(state => state.setMainTextCurrentWord)
    const mainTextCurrentWord = useGame(state => state.mainTextCurrentWord)
    const setMainTextPreviousWord = useGame(state => state.setMainTextPreviousWord)
    const mainTextWordsRef = useGame(state => state.mainTextWordsRef)

    /* Subscribe */
    useEffect(() => 
    {
        const unsubscribeReady = useGame.subscribe(
            (state) => state.phase,
            (phase) => 
            {
                if (phase === 'waiting')
                    reset()
            }
        )

        const unsubscribeSpace = subscribeKeys(
            (state) => state.jump,
            (pressed) => { if (pressed) jump() }
        )
        
        const unsubscribeW = subscribeKeys(
            (state) => state.upward,
            (pressed) => { if (pressed) jump() }
        )

        const unsubscribeEnter = subscribeKeys(
            (state) => state.enter,
            (pressed) => { if (pressed) startGame() }
        )

        return () =>
        {
            unsubscribeReady()
            unsubscribeSpace()
            unsubscribeW()
            unsubscribeEnter()
        }
    }, [phase])


    /* Player jump */
    const jump = () => 
    {
        // Raycaster
        const origin = player.current.translation()
        origin.y -= 0.65
        const direction = { x: 0, y: - 1, z: 0 }
        const ray = new rapier.Ray(origin, direction)
        const hit = world.castRay(ray)

        if (hit.toi < 0.4 && (phase === 'playing' || phase === 'breathing')) 
        {
            player.current.applyImpulse({ x: 0, y: 60, z: 0 })
            jumpSound_play()
        }
    }


    /* reset Game */
    const reset = () =>
    {
        player.current.setTranslation({ x: -4, y: 3, z: 0 })
        player.current.setLinvel({ x: 0, y: 0, z: 0 })
        player.current.setAngvel({ x: 0, y: 0, z: 0 })

        setItemBreathCount(0)
    }

    /* reset Player after Breathing */
    useEffect(() => 
    {
        if(phase === "playing" || phase === "waiting") 
        {
            let position = player.current.translation()
            player.current.setTranslation({ x: position.x, y: position.y, z: 0 })
            player.current.setLinvel({ x: 0, y: 0, z: 0 })
        }
    },
    [phase])


    /* player moves */
    useFrame((state, delta) => 
    {  
        /* 
        ** Animate Player
        */

        const { rightward, leftward, downward, upward, jump } = getKeys()

        // Get linear velocity
        let currentVelocity = player.current.linvel(new THREE.Vector3(0, 0, 0)).x

        // Get PositionX
        let currentPosition = player.current.translation()
        setPlayerPosition(currentPosition)


        const impulse = { x: 0, y: 0, z: 0 }
        const impulseStrength = 60 * delta

        if (rightward && (phase === 'playing' || phase === 'breathing')) 
            impulse.x += impulseStrength

        if (leftward && (phase === 'playing' || phase === 'breathing'))
            impulse.x -= impulseStrength

        if (upward && (phase === 'breathing')) 
            impulse.z -= impulseStrength

        if (downward && (phase === 'breathing'))
            impulse.z += impulseStrength

        if (player.current.linvel().x < 7 && player.current.linvel().x > -7)
            player.current.applyImpulse(impulse)

        /* Check if Player is Slow */
        currentVelocity > 6 ? setPlayerIsFast() : setPlayerIsSlow()


        /* 
        ** Change Phases
        */
        
        if(phase === "playing") 
        {
            levelDimensions.forEach((dimension) => 
            {
                if(gameLevel == dimension.level) 
                {
                    if(currentPosition.x > dimension.left && currentPosition.x < dimension.left + 10) 
                    {
                        breathGame()
                    }
                }
            })
        }

        //Failed
        if(currentPosition.y < -15 && (phase === "playing" || phase === "breathing")) 
        {
            reset()
            setDeathCount(deathCount + 1)
            setGameLevel(1)
            restartGame()
        }
    })


    /* Animate Camera */
    useFrame(({ camera }, delta) => 
    {
        // Camera Position
        const playerPosition = player.current.translation()
        const cameraPosition = new THREE.Vector3()

        // Camera Target
        const cameraTarget = new THREE.Vector3()
        cameraTarget.copy(playerPosition)

        // Translate Position
        cameraPosition.x = cameraTarget.x
        cameraPosition.y += cameraTarget.y * 0.75 + 2
        cameraPosition.z += 6

        // Camera start position
        if (phase === 'waiting') 
        {
            cameraPosition.x = cameraTarget.x - 3
            cameraPosition.y = cameraTarget.y + 1
            cameraPosition.z = cameraPosition.z - 6
        }

        // Camera start position
        if (phase === 'breathing') 
        {
            cameraTarget.z = 0
            cameraTarget.y = -4

            cameraPosition.z = 1
        }

        // Smooth Position and Target
        smoothedCameraPosition.lerp(cameraPosition, 2.5 * delta)
        smoothedCameraTarget.lerp(cameraTarget, 2.5* delta)

        // Set Position and Target
        camera.position.copy(smoothedCameraPosition)
        camera.lookAt(smoothedCameraTarget)
    })


    /* Check on which Block the player is */
    useEffect(() => {
        const getBlockIndex = () => {
            let foundIndex = -1
            wordDimensions.map((dimension, index) => {
                if (playerPosition.x > dimension.left && playerPosition.x < dimension.left + dimension.scale) {
                    foundIndex = index
                }
            })
            return foundIndex
        }

        if (mainTextWordsRef !== undefined) 
            {
                setMainTextCurrentWord(mainTextWordsRef[getBlockIndex()])
                setMainTextPreviousWord(mainTextWordsRef[getBlockIndex() - 1])
            }
    },
        [playerPosition])


    return <>
        <RigidBody 
            ref={ player }
            enabledTranslations={phase === "breathing" ? [true, true, true] : [true, true, false] }
            enabledRotations={ [ false, false, true ] }
            canSleep={ false }

            position={ [ -4, 6, 0 ] }

            restitution={ 0 }
            friction={ 0 }
            linearDamping={ 0.5 }
            density={ 2 }
        >
            <mesh
                geometry={ playerGeometry }
                material={ playerMaterial }
                castShadow
            >
                <Edges material={ edgesMaterial } />
            </mesh>
        </RigidBody>
    </>
}