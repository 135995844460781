import React, { useState, useEffect } from "react";
import { RigidBody } from "@react-three/rapier";
import { Html } from "@react-three/drei";
import { Edges } from "@react-three/drei";
import useGame from "../stores/useGame";
import * as THREE from 'three';

export default function BreathPlattform({
    position,
    geometries,
    materials,
    textures,
    level
}) {
    const phase = useGame((state) => state.phase);
    const setGameLevel = useGame((state) => state.setGameLevel);
    const gameLevel = useGame((state) => state.gameLevel);

    const [countdown, setCountdown] = useState(null); // Initial countdown in tenths of a second

    useEffect(() => {
        if (phase === "breathing") {
            setGameLevel(gameLevel + 1);

            setCountdown(110);

            const timerId = setInterval(() => {
                setCountdown((prevCountdown) => Math.max(prevCountdown - 1, 0));
            }, 100);

            // Clear the interval when the component unmounts or phase changes
            return () => {
                clearInterval(timerId);
            };
        }
    }, [phase]);


    return (
        <RigidBody type="fixed" friction={0} position={[position, 0, 0]}>
            <mesh
                geometry={geometries.boxGeometry}
                material={materials.transparentMaterial}
                position={[5, 0, 0]}
                scale={[10, 0.2, 10]}
                receiveShadow
            >
                <Edges color="white" />
                <Html center className="breathPlattform__countdown">
                    <div className="countdown__display">
                        {countdown !== null && (countdown < 100 ? `${(countdown / 10).toFixed(1)}` : countdown > 100 ? "Breath" : countdown < 10 ? "Level 1" : "")}
                    </div>
                </Html>
            </mesh>

            { (phase === 'breathing' && gameLevel - 1 == level) &&
                <BreathItem
                    geometries={geometries}
                    position={[position, 0, 0]}
                />
            }
        </RigidBody>
    );
}


function BreathItem({
    geometries,
    position
}) {

    const playerPosition = useGame(state => state.playerPosition)
    const setItemBreathCount = useGame(state => state.setItemBreathCount)
    const itemBreathCount = useGame(state => state.itemBreathCount)
    const tolerance = 0.7;

    const [randomX, setRandomX] = useState(0)
    const [randomZ, setRandomZ] = useState(0)

    useEffect(() => {
        const distance = Math.sqrt(
            Math.pow(playerPosition.x - (position[0] + randomX), 2) +
            Math.pow(playerPosition.z - (position[2] + randomZ), 2)
        );

        if (distance <= tolerance) {
            setItemBreathCount(itemBreathCount + 1);

            // Update the position with new random values
            setRandomX((Math.random() - 0.5) * 7 + 5);
            setRandomZ((Math.random() - 0.5) * 7);
        }
    }, [playerPosition]);

    return (
        <group position={[randomX, 1, randomZ]}>
            <mesh
                geometry={geometries.planeGeometry}
            >

                <meshStandardMaterial transparent opacity={0} attach="material" />

                <Html className='items__breath' center>
                    5
                </Html>
            </mesh>
        </group>
    );
}

