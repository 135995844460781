import { useFrame } from "@react-three/fiber"
import { useState } from "react"
import { RigidBody } from '@react-three/rapier';
import { Attractor } from '@react-three/rapier-addons'
import { Html } from "@react-three/drei";
import * as THREE from 'three';


export default function Repulsion_1({ 
    position = [0, 0, 0], 
    scale, 
    textures, 
    geometries,
    materials,
}) 
{
    return <group
        position={position}
    >

        <RigidBody
            type='fixed'
            friction={0}
        >

            <mesh
                geometry={geometries.boxGeometry}
                material={materials.defaultMaterial}
                scale={[scale[0] * 0.5, scale[1], scale[2]]}
                receiveShadow
            >
            </mesh>

            <Attractor
                range={scale[0] * 0.5}
                type='static'
                strength={-2}
                position={[0, 0, 0]}
            />

        </RigidBody>

        <Label
            position={position}
            scale={scale}
        />

        <Blending 
            textures={textures}
            planeGeometry={geometries.planeGeometry}
        />

    </group>
}


function Label({
    position,
    scale
}) {
    const [opacity, setOpacity] = useState(1)

    useFrame(({ clock }) => {
        let time = clock.getElapsedTime()
        setOpacity(Math.sin(time * 5) + 2 * 0.25)
    })

    return <Html style={
        {
            width: scale[0] * 60,
            transform: `translate(-${scale[0] * 60 * 0.5}px, -100px)`,
            opacity: opacity
        }}
        className="plattform__label"
    >

        Danger <br />
        Repulsion

    </Html>
}


function Blending({
    textures,
    planeGeometry
}) 
{
    let [blendingRadius, setBlendingRadius] = useState(1)

    useFrame(({ clock }) => {
        let time = clock.getElapsedTime()
        setBlendingRadius(Math.cos(time * 2))
    })

    return <>

    {/* Circle */}
    <mesh
        geometry={planeGeometry}
        scale={[10 + blendingRadius, 10 + blendingRadius, 0]}
        position={[0, 2, 5]}
    >
        <meshStandardMaterial
            depthWrite={false}
            blending={THREE.CustomBlending}
            blendEquation={THREE.MaxEquation}
            blendSrc={THREE.SrcAlphaFactor}
            blendDst={THREE.OneMinusSrcAlphaFactor}
            metalness={1.52}
            map={textures.circle_1}
        />
    </mesh>

    {/* Triangle */}
    <mesh
        geometry={planeGeometry}
        scale={[8, 8, 0]} 
        position={[0, 2, 0]}
    >
        <meshStandardMaterial
            depthWrite={false}
            blending={THREE.CustomBlending}
            blendEquation={THREE.MaxEquation}
            blendSrc={THREE.SrcAlphaFactor}
            blendDst={THREE.OneMinusSrcAlphaFactor}
            metalness={1.52}
            map={textures.triangle_1}
        />
    </mesh>
    </>
}