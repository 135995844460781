import { useRef, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { Html, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { lerp } from 'three/src/math/MathUtils';
import useSound from 'use-sound';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import * as THREE from 'three'

import useGame from './stores/useGame.jsx';

// Plattforms
import { DynamicPlattform } from './plattforms/Plattforms.jsx'
import StartPlattform from './plattforms/StartPlattform.jsx';
import BreathPlattform from './plattforms/BreathPlattform.jsx';


/* Reusable Utils */

const geometries = 
{
    boxGeometry: new THREE.BoxGeometry(1, 1, 1),
    planeGeometry: new THREE.PlaneGeometry(1, 1, 1, 1)
}

const materials = 
{
    defaultMaterial: new THREE.MeshStandardMaterial({ color: "rgb(255, 255, 255)" }),
    prolongationMaterial: new THREE.MeshStandardMaterial({ color: "rgb(255, 255, 255)" }),
    obstacleMaterial: new THREE.MeshStandardMaterial({ color: "rgb(255, 255, 255)" }),
    transparentMaterial: new THREE.MeshStandardMaterial({ transparent: true, opacity: 0 }),
    blendMaterial: new THREE.MeshStandardMaterial({
        blending: THREE.CustomBlending,
        blendEquation: THREE.MaxEquation,
        blendSrc: THREE.SrcAlphaFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        depthWrite: false,
        metalness: 1.52,
    })
}


/* Level */
export default function Level() 
{

    // Zustand
    const waitingGame = useGame(state => state.waitingGame)
    const startGame = useGame(state => state.startGame)
    const restartGame = useGame(state => state.restartGame)
    const phase = useGame(state => state.phase)
    const setWordDimensions = useGame(state => state.setWordDimensions)
    const wordDimensions = useGame(state => state.wordDimensions)
    const mainTextCurrentWord = useGame(state => state.mainTextCurrentWord)
    const mainTextPreviousWord = useGame(state => state.mainTextPreviousWord)
    const setMainTextWordsRef = useGame(state => state.setMainTextWordsRef)
    const setLevelDimensions = useGame(state => state.setLevelDimensions)
    const levelDimensions = useGame(state => state.levelDimensions)
    const gameLevel = useGame(state => state.gameLevel)
    const setGameLevel = useGame(state => state.setGameLevel)
    const resetGameLevel = useGame(state => state.resetGameLevel)

    /* Load Textures */
    const textures = {}
    textures.circle_1 = useTexture('/game/images/blend_circle-1.jpg')
    textures.triangle_1 = useTexture('/game/images/blend_triangle-1.jpg')
    textures.triangle_2 = useTexture('/game/images/blend_triangle-2.jpg')
    textures.square_1 = useTexture('/game/images/blend_square-1.jpg')
    textures.friction_begin = useTexture('/game/images/blend_friction-begin.jpg')
    textures.friction_end = useTexture('/game/images/blend_friction-end.jpg')
    textures.friction_middle = useTexture('/game/images/blend_friction-middle.jpg')
    textures.physics_1 = useTexture('/game/images/blend_physics-1.jpg')
    textures.breath_1 = useTexture('/game/images/breath_circles-1.jpg')
    textures.breath_2 = useTexture('/game/images/breath_circles-2.jpg')
    textures.physics_2 = useTexture('/game/images/blend_physics-2.jpg')
    textures.physics_3 = useTexture('/game/images/blend_physics-3.jpg')
    textures.repetition_1 = useTexture('/game/images/blend_repetition-1.jpg')

    // References
    const htmlRef = useRef(null);
    const mainTextWordsRef = useRef([])
    const mainTextRef = useCallback(node => 
    {
        if (node !== null) {
            htmlRef.current = node;
        }
    }, 
    []);


    /* Load Game*/
    useEffect(() => 
    {
        const timeoutId = setTimeout(() => 
        {
            waitingGame()
            setGameLevel(1)
        }, 100);

        return () => clearTimeout(timeoutId);
    }, 
    []);


    /* Fill and Calculate Word Dimensions */
    useLayoutEffect(() => {
        if (htmlRef.current && (phase === 'waiting' || phase === 'breathing')) 
        {
            const mainText = htmlRef.current.querySelectorAll('.mainText__word, .mainText__breath');
            const mainTextContainer = htmlRef.current

            const children = [];

            // Calculate Dimensions of every Block according to word Dimensions
            Array.from(mainText).forEach((child, index) => 
            {
                children.push(
                    {
                    scale: child.getBoundingClientRect().width / 60 -0.5,
                    position: (child.getBoundingClientRect().left - mainTextContainer.getBoundingClientRect().left) / 60 + child.getBoundingClientRect().width / 120 + 0.5,
                    left: (child.getBoundingClientRect().left - mainTextContainer.getBoundingClientRect().left) / 60,
                    right: child.offsetLeft / 60 + child.getBoundingClientRect().width / 60,
                    ligature: child.firstChild.innerHTML.slice(7),
                    index: index,
                    isBreath: child.classList.item(0) === "mainText__breath" ? true : false,
                    level: child.getAttribute('data-level')
                });
            });

            setWordDimensions(children);

            stop()
        }
    }, [phase]);


    /* Fill Level Dimensions Left */
    useEffect(() => 
    {
        const children = []

        if(wordDimensions.length !== 0) {
            wordDimensions.forEach((child) => 
            {
                if(child.isBreath) {
                    children.push({left: child.left, level: child.level})
                }
            })
        }

        setLevelDimensions(children)
    },
    [wordDimensions])


    /* Set the References for all Words */
    useEffect(() => 
    {
        const refs = []
        const elements = document.querySelectorAll('.mainText__word')
        elements.forEach((element, index) => 
        {
            refs.push(element)
        })

        setMainTextWordsRef(refs)
    }, 
    [phase === 'waiting'])


    useEffect(() => 
    {
        if (phase === 'breathing') 
        {
            const timerId = setTimeout(() => 
            {
                startGame()
            }, 11000);

            return () => clearTimeout(timerId);
        }
    }, 
    [phase === 'breathing'])


    useGSAP(() => 
    {
        const tl = gsap.timeline();
        tl.clear();

        // tl.fromTo(
        //     mainTextCurrentWord,
        //     { fontVariationSettings: "'wdth' 500" },
        //     { fontVariationSettings: "'wdth' 100", duration: 1, ease: 'bounce' },
        //     0
        // );
        // tl.fromTo(
        //     mainTextPreviousWord,
        //     { fontVariationSettings: "'wdth' 100" },
        //     { fontVariationSettings: "'wdth' 500", duration: 1, ease: 'ease-out' },
        //     0
        // );
        tl.fromTo(
            mainTextCurrentWord,
            { opacity: "0.3" },
            { opacity: "1", duration: 1, ease: 'ease' },
            0
        );
        tl.fromTo(
            mainTextPreviousWord,
            { opacity: "1", },
            { opacity: "0.3", duration: 1, ease: 'ease' },
            0
        );
    },
    {dependencies: [mainTextCurrentWord]})


    return (
        <>
            {/* render main Text */}
            <Html 
                // occlude="blending"
                ref={ mainTextRef } 
                className='mainText'
                position={[0, -2, 0]}
            >
                <div data-level="1" className={ ` mainText__level  
                    ${ phase === 'playing' && gameLevel == '1' ? 'mainText--visible' : '' }` }
                >

                    <span className="mainText__word" data-level="1" id='1'>
                        <span className='word__ligature'>&nbsp; Di</span>
                        es
                    </span> &nbsp;
                    
                    <span className="mainText__word" data-level="1" id='3'>
                        <span className='word__ligature'>&nbsp; i</span>st
                    </span> &nbsp;

                    <span className="mainText__word" data-level="1" id='4'>
                        <span className='word__ligature'>&nbsp; e</span>ine
                    </span> &nbsp;

                    <span className="mainText__word" data-level="1" id='5'>
                        <span className='word__ligature'>&nbsp; De</span>mo
                    </span> &nbsp;

                    <span className="mainText__word" data-level="1" id='6'>
                        <span className='word__ligature'>&nbsp; mei</span>nes
                    </span> &nbsp;
                    
                    <span className="mainText__word" data-level="1" id='7'>
                        <span className='word__ligature'>&nbsp; Ga</span>mes
                    </span> &nbsp;

                    <span className="mainText__breath mainText__word" data-level="1" id='8'>
                        <span className='word__ligature' >&#44;</span>
                    </span> &nbsp;

                </div>

                <div data-level="2" style={{ transform: `translate(600px, 0)` }} className={` mainText__level 
                    ${phase === 'playing' && gameLevel == '2' ? 'mainText--visible' : ''}`}
                >

                    <span className="mainText__word" data-level="2" id='9'>
                        <span className='word__ligature'>&nbsp; we</span>lches
                    </span> &nbsp;

                    <span className="mainText__word" data-level="2" id='10'>
                        <span className='word__ligature'>&nbsp; die</span>
                    </span> &nbsp;

                    <span className="mainText__word" data-level="2" id='11'>
                        <span className='word__ligature'>&nbsp; Ab</span>sicht
                    </span> &nbsp;

                    <span className="mainText__word" data-level="2" id='12'>
                        <span className='word__ligature'>&nbsp; ve</span>rfolgt
                    </span> &nbsp;

                    <span className="mainText__breath mainText__word" data-level="2" id='13'>
                        <span className='word__ligature' >&#44;</span>
                    </span> &nbsp;

                </div>

                <div data-level="3" style={{ transform: `translate(1200px, 0)` }} className={` mainText__level 
                    ${phase === 'playing' && gameLevel == '3' ? 'mainText--visible' : ''}`}
                >

                    <span className="mainText__word" data-level="3" id='14'>
                        <span className='word__ligature'>&nbsp; di</span>e
                    </span> &nbsp;

                    <span className="mainText__word" data-level="3" id='15'>
                        <span className='word__ligature'>&nbsp; Sp</span>rechstörung
                    </span> &nbsp;

                    <span className="mainText__word" data-level="3" id='16'>
                        <span className='word__ligature'>&nbsp; St</span>ottern
                    </span> &nbsp;

                    <span className="mainText__word" data-level="3" id='17'>
                        <span className='word__ligature'>&nbsp; er</span>lebbar
                    </span> &nbsp;

                    <span className="mainText__word" data-level="3" id='18'>
                        <span className='word__ligature'>&nbsp; zu</span>
                    </span> &nbsp;

                    <span className="mainText__word" data-level="3" id='19'>
                        <span className='word__ligature'>&nbsp; ma</span>chen
                    </span> &nbsp;

                    <span className="mainText__breath mainText__word" data-level="3" id='20'>
                        <span className='word__ligature' >.</span>
                    </span> &nbsp;

                </div>

                <div data-level="4" style={{ transform: `translate(1800px, 0)` }} className={` mainText__level 
                    ${phase === 'playing' && gameLevel == '4' ? 'mainText--visible' : ''}`}
                >

                    <span className="mainText__word" data-level="4" id='21'>
                        <span className='word__ligature'>&nbsp; Je</span>
                    </span> &nbsp;

                    <span className="mainText__word" data-level="4" id='22'>
                        <span className='word__ligature'>&nbsp; lä</span>nger
                    </span> &nbsp;

                    <span className="mainText__word" data-level="4" id='23'>
                        <span className='word__ligature'>&nbsp; di</span>e
                    </span> &nbsp;

                    <span className="mainText__word" data-level="4" id='24'>
                        <span className='word__ligature'>&nbsp; Be</span>troffene
                    </span> &nbsp;

                    <span className="mainText__word" data-level="4" id='25'>
                        <span className='word__ligature'>&nbsp; sp</span>richt
                    </span> &nbsp;

                    <span className="mainText__breath mainText__word" data-level="4" id='26'>
                        <span className='word__ligature' >&#44;</span>
                    </span> &nbsp;

                </div>

                <div data-level="5" style={{ transform: `translate(2400px, 0)` }} className={` mainText__level 
                    ${phase === 'playing' && gameLevel == '5' ? 'mainText--visible' : ''}`}
                >

                    <span className="mainText__word" data-level="5" id='27'>
                        <span className='word__ligature'>&nbsp; de</span>sto
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='28'>
                        <span className='word__ligature'>&nbsp; schw</span>erer
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='29'>
                        <span className='word__ligature'>&nbsp; fä</span>llt
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='30'>
                        <span className='word__ligature'>&nbsp; ih</span>r
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='31'>
                        <span className='word__ligature'>&nbsp; da</span>s
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='32'>
                        <span className='word__ligature'>&nbsp; Fo</span>rmulieren
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='33'>
                        <span className='word__ligature'>&nbsp; un</span>d
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='34'>
                        <span className='word__ligature'>&nbsp; da</span>s
                    </span> &nbsp;

                    <span className="mainText__word" data-level="5" id='35'>
                        <span className='word__ligature'>&nbsp; At</span>men
                    </span> &nbsp;

                    <span className="mainText__breath mainText__word" data-level="5" id='36'>
                        <span className='word__ligature' >&#44;</span>
                    </span> &nbsp;

                </div>


            </Html>

            <StartPlattform
                textures={textures}
                geometries={geometries}
                materials={materials}
            />  

            {/* Render Plattforms */}
            {phase === 'playing' &&
                wordDimensions.map((dimension, index) => 
                {
                    if (dimension.level == gameLevel) 
                    {
                        return (
                            <DynamicPlattform
                                key={`${dimension.left}-${dimension.width}-${index}`}
                                dimension={dimension}
                                textures={textures}
                                geometries={geometries}
                                materials={materials}
                            />
                        );
                    } else 
                    {
                        return null;
                    }
                })
            }

{/*             {(phase === 'playing' || phase === 'breathing') &&
                levelDimensions.map((dimension, index) => 
                {
                    if (dimension.level == gameLevel) 
                    {
                        return (
                            <BreathPlattform
                                key={index}
                                position={dimension.left}
                                textures={textures}
                                geometries={geometries}
                                materials={materials}
                                level={dimension.level}
                            />
                        );
                    } else 
                    {
                        return null;
                    }
                })
            } */}

            {(phase === 'playing' || phase === 'breathing') &&
                levelDimensions.map((dimension, index) => 
                {
                        return (
                            <BreathPlattform
                                key={index}
                                position={dimension.left}
                                textures={textures}
                                geometries={geometries}
                                materials={materials}
                                level={dimension.level}
                            />
                        );
                })
            }
        </>
    );
}
