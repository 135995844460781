import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { DirectionalLight } from 'three'

export default function Lights()
{
    const directionalLight = useRef()

    // light and target tracks player
    useFrame((state) => 
    {
        directionalLight.current.position.x = state.camera.position.x + 3
        directionalLight.current.target.position.x = state.camera.position.x
        directionalLight.current.target.updateMatrixWorld()
    })

    return <>
        <directionalLight
            ref={ directionalLight }
            castShadow
            position={ [ 4, 4, 1 ] }
            intensity={ 10 }
            shadow-mapSize={ [ 1024, 1024 ] }
            shadow-camera-near={ 1 }
            shadow-camera-far={ 20 }
            shadow-camera-top={ 10 }
            shadow-camera-right={ 10 }
            shadow-camera-bottom={ - 10 }
            shadow-camera-left={ - 10 }
        />
        <ambientLight 
            intensity={ 2 } 
        />
    </>
}