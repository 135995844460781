export const megiZumsteinPlakate =
{
    main:
    {
        title: "Plakate bei Megi Zumstein",
        category: "Poster",
        page: "/megiZumsteinPlakate"
    },

    isometrie:
    {
        type: ".gif",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "Internship", content: "∙Megi Zumstein <br> &nbsp;visuelle <br> &nbsp;Gestaltung" },
            { title: "proportions", content: "∙160 &times; 210 <br>∙895 &times; 1280" },
            { title: "printing", content: "∙Screenprinting <br> ∙offset <br> ∙digital" },
            { title: "customers", content: "∙Museum für <br> &nbsp;Gestaltung Zürich <br> ∙Baltensweiler" },
        ],

    text:
    {
        adjectives:
            [
                { de: "reduziert", en: "reduced" },
                { de: "Rasterungen", en: "Rasterizations" },
                { de: "Kreis", en: "Circle" },
                { de: "Licht", en: "Light" },
                { de: "klassisch", en: "classic" },
            ],

        content:
        {
            de: `
            Für Lampendesignerin Rosmarie Baltensweiler, sowie für Keramikdesignerin Margrit Linck gestalteten wir die Werbemittel. Diese beiden Aufträge haben gemeinsam, dass das Lebenswerk der Schöpferinnen im Vordergrund stehen sollen und wie als Grafiker:innen nur ‹Ausführende› des Werbeauftrages sind. Als Grafiker einen eigenen Style zu etablieren ohne die Produkte zu ‹verdecken› war hierbei besonders schwierig.
            ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/layoutBasics/0_ansichten/400.jpg",
                        "/images/layoutBasics/0_ansichten/600.jpg",
                        "/images/layoutBasics/0_ansichten/800.jpg",
                        "/images/layoutBasics/0_ansichten/1000.jpg",
                        "/images/layoutBasics/0_ansichten/1500.jpg",
                        "/images/layoutBasics/0_ansichten/2000.jpg",
                        "/images/megiZumsteinPlakate/0_ansichten/3000.jpg",
                        "/images/layoutBasics/0_ansichten/4000.jpg",
                        "/images/layoutBasics/0_ansichten/5000.jpg"
                    ]
            },

            // video 1
            {
                type: "videoBig",
                source: "/images/megiZumsteinPlakate/1_video/video.mp4"
            },

            // single 2
            {
                type: "single3D",
                srcset:
                    [
                        "/images/megiZumsteinPlakate/2_single/400.jpg",
                        "/images/megiZumsteinPlakate/2_single/600.jpg",
                        "/images/megiZumsteinPlakate/2_single/800.jpg",
                        "/images/megiZumsteinPlakate/2_single/1000.jpg",
                        "/images/megiZumsteinPlakate/2_single/1500.jpg",
                        "/images/megiZumsteinPlakate/2_single/2000.jpg",
                        "/images/megiZumsteinPlakate/2_single/3000.jpg",
                        "/images/megiZumsteinPlakate/2_single/4000.jpg",
                        "/images/megiZumsteinPlakate/2_single/5000.jpg"
                    ],
                background: "white"
            },

            // single 4
            {
                type: "single2D",
                srcset:
                    [
                        "/images/megiZumsteinPlakate/4_single/400.jpg",
                        "/images/megiZumsteinPlakate/4_single/600.jpg",
                        "/images/megiZumsteinPlakate/4_single/800.jpg",
                        "/images/megiZumsteinPlakate/4_single/1000.jpg",
                        "/images/megiZumsteinPlakate/4_single/1500.jpg",
                        "/images/megiZumsteinPlakate/4_single/2000.jpg",
                        "/images/megiZumsteinPlakate/4_single/3000.jpg",
                        "/images/megiZumsteinPlakate/4_single/4000.jpg",
                        "/images/megiZumsteinPlakate/4_single/5000.jpg"
                    ],
                background: "black"
            },

            // single 7
            {
                type: "single3D",
                srcset:
                    [
                        "/images/megiZumsteinPlakate/7_single/400.jpg",
                        "/images/megiZumsteinPlakate/7_single/600.jpg",
                        "/images/megiZumsteinPlakate/7_single/800.jpg",
                        "/images/megiZumsteinPlakate/7_single/1000.jpg",
                        "/images/megiZumsteinPlakate/7_single/1500.jpg",
                        "/images/megiZumsteinPlakate/7_single/2000.jpg",
                        "/images/megiZumsteinPlakate/7_single/3000.jpg",
                        "/images/megiZumsteinPlakate/7_single/4000.jpg",
                        "/images/megiZumsteinPlakate/7_single/5000.jpg"
                    ],
                background: "black"
            },

            // videos 3
            {
                type: "videoSmall",
                sources:
                    [
                        "/images/megiZumsteinPlakate/12_video/1_video.mp4",
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/megiZumsteinPlakate/13_swiper/1_image/400.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/600.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/800.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/1000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/1500.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/2000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/3000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/4000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/13_swiper/2_image/400.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/600.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/800.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/1000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/1500.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/2000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/3000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/4000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/13_swiper/3_image/400.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/600.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/800.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/1000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/1500.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/2000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/3000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/4000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/13_swiper/4_image/400.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/600.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/800.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/1000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/1500.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/2000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/3000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/4000.jpg",
                            "/images/megiZumsteinPlakate/13_swiper/4_image/5000.jpg"
                        ],
                    ]
            },

            // single 8
            {
                type: "single3D",
                srcset:
                    [
                        "/images/megiZumsteinPlakate/8_single/400.jpg",
                        "/images/megiZumsteinPlakate/8_single/600.jpg",
                        "/images/megiZumsteinPlakate/8_single/800.jpg",
                        "/images/megiZumsteinPlakate/8_single/1000.jpg",
                        "/images/megiZumsteinPlakate/8_single/1500.jpg",
                        "/images/megiZumsteinPlakate/8_single/2000.jpg",
                        "/images/megiZumsteinPlakate/8_single/3000.jpg",
                        "/images/megiZumsteinPlakate/8_single/4000.jpg",
                        "/images/megiZumsteinPlakate/8_single/5000.jpg"
                    ],
                background: "black"
            },

            // single 9
            {
                type: "single2D",
                srcset:
                    [
                        "/images/megiZumsteinPlakate/9_single/400.jpg",
                        "/images/megiZumsteinPlakate/9_single/600.jpg",
                        "/images/megiZumsteinPlakate/9_single/800.jpg",
                        "/images/megiZumsteinPlakate/9_single/1000.jpg",
                        "/images/megiZumsteinPlakate/9_single/1500.jpg",
                        "/images/megiZumsteinPlakate/9_single/2000.jpg",
                        "/images/megiZumsteinPlakate/9_single/3000.jpg",
                        "/images/megiZumsteinPlakate/9_single/4000.jpg",
                        "/images/megiZumsteinPlakate/9_single/5000.jpg"
                    ],
                background: "rgb(145, 111, 195)"
            },

            // swiper 1
            {
                type: "swiperFullScreen",
                srcset:
                    [
                        [
                            "/images/megiZumsteinPlakate/10_swiper/2_image/400.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/600.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/800.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/1000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/1500.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/2000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/3000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/4000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/10_swiper/3_image/400.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/600.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/800.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/1000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/1500.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/2000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/3000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/4000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/10_swiper/4_image/400.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/600.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/800.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/1000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/1500.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/2000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/3000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/4000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/10_swiper/5_image/400.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/600.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/800.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/1000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/1500.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/2000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/3000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/4000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/megiZumsteinPlakate/10_swiper/6_image/400.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/600.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/800.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/1000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/1500.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/2000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/3000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/4000.jpg",
                            "/images/megiZumsteinPlakate/10_swiper/6_image/5000.jpg"
                        ],
                    ]
            },
        ],
}