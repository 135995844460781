import { theGameOfStuttering } from "./theGameOfStuttering";
import { lausanneProject } from "./lausanneProject";
import { ineffizienz } from "./ineffizienz";
import { music } from "./music";
import { layoutBasics } from "./layoutBasics";
import { london } from "./london";
import { korrespondenzkarten } from "./korrespondenzkarten";
import { jungeGrafik } from "./jungeGrafik";
import { rumpelkiste } from "./rumpelkiste";
import { megiZumsteinPlakate } from "./megiZumsteinPlakate";
import { megiZumsteinEditorial } from "./megiZumsteinEditorial";
import { aboutMe } from "./aboutMe";
import { nachtDerBibliotheken } from "./nachtDerBibliotheken";


export const data = 
{
    theGameOfStuttering,
    lausanneProject,
    jungeGrafik,
    ineffizienz,
    music,
    layoutBasics,
    nachtDerBibliotheken,
    london,
    korrespondenzkarten,
    megiZumsteinPlakate,
    megiZumsteinEditorial,
    rumpelkiste,

    aboutMe
}