export const rumpelkiste =
{
    main:
    {
        title: "Rumpelkiste",
        category: "small projects",
        page: "/rumpelkiste"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: "∙various <br> ∙digital" },
            { title: "year", content: "∙since <br> &nbsp;2020" },
            { title: "weight", content: "∙various <br> ∙digital" },
        ],

    text:
    {
        adjectives:
            [
                { de: "Ansätze", en: "Approaches" },
                { de: "Experimente", en: "Experiments" },
                { de: "künstlerisch", en: "artistic" },
                { de: "analog", en: "analog" },
                { de: "illustrativ", en: "illustrative" },
            ],

        content:
        {
            de: `
            Eine unvollständige Sammlung einiger Ansätze und Experimente aus kleineren Projekten und Aufträgen.` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "ansichten",
                srcset:
                    [
                        "/images/rumpelkiste/0_ansichten/400.jpg",
                        "/images/rumpelkiste/0_ansichten/600.jpg",
                        "/images/rumpelkiste/0_ansichten/800.jpg",
                        "/images/rumpelkiste/0_ansichten/1000.jpg",
                        "/images/rumpelkiste/0_ansichten/1500.jpg",
                        "/images/rumpelkiste/0_ansichten/2000.jpg",
                        "/images/rumpelkiste/0_ansichten/3000.jpg",
                        "/images/rumpelkiste/0_ansichten/4000.jpg",
                        "/images/rumpelkiste/0_ansichten/5000.jpg"
                    ]
            },


            /* FG Flyer 2024 */
            {
                type: "single2D",
                srcset:
                    [
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/400.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/600.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/800.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/1000.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/1500.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/2000.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/3000.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/4000.jpg",
                        "/images/rumpelkiste/4_fgFlyer2024/1_single/5000.jpg"
                    ],
            },


            /* Warpex */
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/400.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/600.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/800.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/1000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/1500.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/2000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/3000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/4000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/400.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/600.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/800.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/1000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/1500.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/2000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/3000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/4000.jpg",
                            "/images/rumpelkiste/1_warpex/1_swiper/2_image/5000.jpg"
                        ]
                    ],
                background: "white"
            },

            {
                type: "double",
                srcset:
                    [
                        [
                            "/images/rumpelkiste/1_warpex/2_double/1_image/400.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/600.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/800.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/1000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/1500.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/2000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/3000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/4000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/1_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/1_warpex/2_double/2_image/400.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/600.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/800.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/1000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/1500.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/2000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/3000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/4000.jpg",
                            "/images/rumpelkiste/1_warpex/2_double/2_image/5000.jpg"
                        ]
                    ],
                background: "black"
            },


            /* Les Videos */
            {
                type: "single2D",
                srcset:
                    [
                        "/images/rumpelkiste/2_lesVideos/2_single/400.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/600.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/800.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/1000.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/1500.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/2000.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/3000.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/4000.jpg",
                        "/images/rumpelkiste/2_lesVideos/2_single/5000.jpg"
                    ],
                background: "black",
                padding: "0 0 0 250px"
            },

            {
                type: "single3D",
                srcset:
                    [
                        "/images/rumpelkiste/2_lesVideos/1_single/400.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/600.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/800.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/1000.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/1500.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/2000.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/3000.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/4000.jpg",
                        "/images/rumpelkiste/2_lesVideos/1_single/5000.jpg"
                    ],
            },

            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/400.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/600.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/800.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/1000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/1500.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/2000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/3000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/4000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/400.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/600.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/800.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/1000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/1500.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/2000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/3000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/4000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/400.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/600.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/800.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/1000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/1500.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/2000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/3000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/4000.jpg",
                            "/images/rumpelkiste/2_lesVideos/3_swiper/3_image/5000.jpg"
                        ],
                    ],
                background: "black"
            },


            /* Fastfood Fehler */
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/400.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/600.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/800.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/1000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/1500.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/2000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/3000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/4000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/400.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/600.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/800.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/1000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/1500.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/2000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/3000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/4000.jpg",
                            "/images/rumpelkiste/3_fastfoodFehler/1_swiper/2_image/5000.jpg"
                        ],
                    ],
            },

            {
                type: "single3D",
                srcset:
                    [
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/400.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/600.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/800.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/1000.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/1500.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/2000.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/3000.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/4000.jpg",
                        "/images/rumpelkiste/3_fastfoodFehler/3_single/5000.jpg"
                    ],
            },

            // {
            //     type: "single2D",
            //     srcset:
            //         [
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/400.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/600.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/800.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/1000.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/1500.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/2000.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/3000.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/4000.jpg",
            //             "/images/rumpelkiste/3_fastfoodFehler/4_single/5000.jpg"
            //         ],
            //         background: "black"
            // },


            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/400.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/600.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/800.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/1000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/1500.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/2000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/3000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/4000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/400.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/600.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/800.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/1000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/1500.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/2000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/3000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/4000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/400.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/600.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/800.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/1000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/1500.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/2000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/3000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/4000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/400.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/600.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/800.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/1000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/1500.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/2000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/3000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/4000.jpg",
                            "/images/rumpelkiste/5_umsetzung/1_swiper/4_image/5000.jpg"
                        ],
                    ],
            },

            /* Escape Box */
            {
                type: "videoBig",
                source: "/images/rumpelkiste/7_escapeBox/1_video/video.mp4"
            },

            /* Kopfzeichnen */
            {
                type: "single2D",
                srcset:
                    [
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/400.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/600.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/800.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/1000.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/1500.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/2000.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/3000.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/4000.jpg",
                        "/images/rumpelkiste/6_kopfzeichnen/1_single/5000.jpg"
                    ],
            },

        ],
}