import { createRoot } from 'react-dom/client'
import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';


import Project from './Components/Project.jsx'
import TheGameOfStuttering from './Components/TheGameOfStuttering.jsx'

import './style/style.css';

import { data } from './data/data.jsx';

const root = createRoot(document.querySelector('#root'))

root.render
(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>

                <Route index element={<TheGameOfStuttering data={ data.theGameOfStuttering } /> }/>
                <Route path='/theGameOfStuttering' element={<TheGameOfStuttering data={data.theGameOfStuttering} /> }/>
                <Route path='/lausanneProject' element={<Project data={ data.lausanneProject } /> }/>
                <Route path='/jungeGrafik' element={<Project data={ data.jungeGrafik } /> }/>
                <Route path='/ineffizienz' element={<Project data={ data.ineffizienz } /> }/>
                <Route path='/music' element={<Project data={ data.music } /> }/>
                <Route path='/layoutBasics' element={<Project data={ data.layoutBasics } /> }/>
                <Route path='/london' element={<Project data={ data.london } /> }/>
                <Route path='/korrespondenzkarten' element={<Project data={ data.korrespondenzkarten } /> }/>
                <Route path='/megiZumsteinPlakate' element={<Project data={ data.megiZumsteinPlakate } /> }/>
                <Route path='/megiZumsteinEditorial' element={<Project data={ data.megiZumsteinEditorial } /> }/>
                <Route path='/nachtDerBibliotheken' element={<Project data={ data.nachtDerBibliotheken } /> }/>
                <Route path='/rumpelkiste' element={<Project data={ data.rumpelkiste } /> }/>
                <Route path='/aboutMe' element={<Project data={ data.aboutMe } /> }/>

            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)