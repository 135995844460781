import './gameStyle.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { KeyboardControls } from '@react-three/drei'
import { useState } from 'react'

import useGame from './stores/useGame.jsx'

import Game from './Game.jsx'
import Ressources from './interface/Ressources.jsx'
import Gradients from './interface/Gradients.jsx'
import StartScreen from './interface/StartScreen.jsx'
import Sound from './Helpers/Sound.jsx'
import Progress from './interface/Progress.jsx'
import KeysExplenation from './interface/KeysExplenation.jsx'

export default function App() 
{
    const phase = useGame(state => state.phase)

    return <div className="app">
            <KeyboardControls
                map={[
                    { name: "rightward", keys: ["ArrowRight", "KeyD"] },
                    { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
                    { name: "upward", keys: ["ArrowUp", "KeyW"] },
                    { name: "downward", keys: ["ArrowDown", "KeyS"] },
                    { name: "jump", keys: ["Space"] },
                    { name: "enter", keys: ["Enter"] },
                ]}
            >
                <Canvas
                    className='app__canvas'
                    orthographic
                    shadows
                    camera={{
                        fov: 45,
                        near: -20,
                        far: 400,
                        position: [0, 4, 12],
                        zoom: 60
                    }}
                >
                    <Game />
                </Canvas>

            {/* HTML */}
                <Ressources />
                <Sound />
            {phase === 'waiting' && <StartScreen />}
            {phase === 'waiting' && <KeysExplenation />}

            </KeyboardControls>
    </div>
}