export const theGameOfStuttering =
{
    main:
    {
        title: "The Game of Stuttering",
        category: "Game&#8198;/&#8198;Font&#8198;/&#8198;Editorial",
        page: "/theGameOfStuttering"
    },

    isometrie:
    {
        type: ".png",
        area: "110, 0, 220, 60, 110, 120, 0, 60"
    },

    information:
        [
            { title: "proportions", content: " ∙170 &times; 240 <br> ∙digital" },
            { title: "year", content: "∙since <br> &nbsp;2024" },
            { title: "languages", content: "∙CSS <br> ∙Javascript <br> ∙Html" },
            { title: "frameworks", content: "∙React <br> ∙THREE.js <br> ∙GSAP" },
            { title: "production", content: "∙digital <br> ∙chainstitch" }
        ],

    text:
    {
        adjectives:
            [
                { de: "Redefluss", en: "Flow of speech" },
                { de: "experimentell", en: "experimental" },
                { de: "Hürden", en: "Obstacles " },
                { de: "Rhytmus", en: "Rhythm" },
                { de: "Musik", en: "Music" },
            ],

        content:
        {
            de: `
            Für meine Abschlussarbeit an der Fachklasse Grafik habe ich mich für das persönliche Thema Stottern entschieden. Es handelt sich dabei um eine Sprechstörung, bei der Silben oder Buchstaben Wiederholt, gedehnt oder Blockiert werden. Dadurch entstehen Unterbrüche im Redefluss. Um mich diesem irrationalen Thema zu nähern, habe ich mich im Prozess für drei Medien entschieden: Es ist ein Editorial, eine Custom Font, sowie ein Webgame entstanden.
            ` ,

            en: `Diese Zeitung entstand parallel mit dem Plakat «Ineffizienz».Bei diesem lag der Fokus eher auf den Einfluss auf die Umwelt, während sich dieses Projekt auf das Leben der Nutztiere fokussiert.Das analoge Arbeiten ist bei diesem Projekt zentral.Grundsätzlich stört mich an der heutigen Grafik, dass alles nur noch digital entsteht, wobei durch die Handarbeit viel lebendigere Arbeiten entstehen können.Wenn wir etwas physisch vor uns haben, entwickeln wir einen anderen Bezug dazu.Dieses Projekt soll ein Protest gegen digitalen Hochglanz sein!` ,
        }
    },

    sources:
        [
            // ansichten 0
            {
                type: "none",
                srcset:
                    [
                        "/images/theGameOfStuttering/0_ansichten/400.jpg",
                        "/images/theGameOfStuttering/0_ansichten/600.jpg",
                        "/images/theGameOfStuttering/0_ansichten/800.jpg",
                        "/images/theGameOfStuttering/0_ansichten/1000.jpg",
                        "/images/theGameOfStuttering/0_ansichten/1500.jpg",
                        "/images/theGameOfStuttering/0_ansichten/2000.jpg",
                        "/images/theGameOfStuttering/0_ansichten/3000.jpg",
                        "/images/theGameOfStuttering/0_ansichten/4000.jpg",
                        "/images/theGameOfStuttering/0_ansichten/5000.jpg"
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/theGameOfStuttering/1_swiper/1_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/2_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/3_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/4_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/5_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/6_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/6_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/1_swiper/7_image/400.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/600.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/800.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/1000.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/1500.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/2000.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/3000.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/4000.jpg",
                            "/images/theGameOfStuttering/1_swiper/7_image/5000.jpg"
                        ],
                    ]
            },

            // videos 3
            {
                type: "videoBig",
                source: "/images/theGameOfStuttering/6_video/1_video.mp4"
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/theGameOfStuttering/5_swiper/1_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/2_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/8_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/8_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/3_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/4_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/7_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/7_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/5_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/5_swiper/6_image/400.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/600.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/800.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/1000.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/1500.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/2000.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/3000.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/4000.jpg",
                            "/images/theGameOfStuttering/5_swiper/6_image/5000.jpg"
                        ],
                    ]
            },

            // swiper 1
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/theGameOfStuttering/2_swiper/1_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/2_swiper/2_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/2_swiper/3_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/2_swiper/4_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/2_swiper/5_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/5_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/2_swiper/6_image/400.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/600.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/800.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/1000.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/1500.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/2000.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/3000.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/4000.jpg",
                            "/images/theGameOfStuttering/2_swiper/6_image/5000.jpg"
                        ],
                    ]
            },

            // ansichten 0
            {
                type: "single3D",
                srcset:
                    [
                        "/images/theGameOfStuttering/3_single/400.jpg",
                        "/images/theGameOfStuttering/3_single/600.jpg",
                        "/images/theGameOfStuttering/3_single/800.jpg",
                        "/images/theGameOfStuttering/3_single/1000.jpg",
                        "/images/theGameOfStuttering/3_single/1500.jpg",
                        "/images/theGameOfStuttering/3_single/2000.jpg",
                        "/images/theGameOfStuttering/3_single/3000.jpg",
                        "/images/theGameOfStuttering/3_single/4000.jpg",
                        "/images/theGameOfStuttering/3_single/5000.jpg"
                    ]
            },

            // swiper 4
            {
                type: "swiper",
                srcset:
                    [
                        [
                            "/images/theGameOfStuttering/4_swiper/1_image/400.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/600.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/800.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/1000.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/1500.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/2000.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/3000.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/4000.jpg",
                            "/images/theGameOfStuttering/4_swiper/1_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/4_swiper/2_image/400.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/600.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/800.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/1000.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/1500.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/2000.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/3000.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/4000.jpg",
                            "/images/theGameOfStuttering/4_swiper/2_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/4_swiper/3_image/400.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/600.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/800.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/1000.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/1500.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/2000.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/3000.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/4000.jpg",
                            "/images/theGameOfStuttering/4_swiper/3_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/4_swiper/4_image/400.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/600.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/800.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/1000.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/1500.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/2000.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/3000.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/4000.jpg",
                            "/images/theGameOfStuttering/4_swiper/4_image/5000.jpg"
                        ],
                        [
                            "/images/theGameOfStuttering/4_swiper/5_image/400.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/600.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/800.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/1000.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/1500.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/2000.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/3000.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/4000.jpg",
                            "/images/theGameOfStuttering/4_swiper/5_image/5000.jpg"
                        ],
                    ]
            },


        ],
}